import React from "react";
import { get, isCancel } from "axios";
import { defineMessages } from "react-intl";
import v4 from "uuid/v4";
import pipe from "pipe-now";
import { differenceWith, groupBy, uniqBy, sumBy, uniq } from "lodash";
import cuid from "cuid";
import moment from "moment-timezone";
import quotedPrintable from "quoted-printable";
import utf8 from "utf8";
import { renderToStaticMarkup } from "react-dom/server";
import { JssProvider, SheetsRegistry, jss } from "react-jss";
import { Provider } from "react-redux";

import createAction from "../../../_common/utils/redux/createAction";
import AppWrapper from "../../../_common/components/AppWrapper";
import LinksAnexos from "../../../pages/central-emails/LinksAnexos/LinksAnexos";
import { ENTRADA, BUSCAR, PROVIDERS } from "../../../pages/central-emails/constantes";
import { objectKeysToCamelCase } from "../../../_common/utils/string/string";
import { removeRepetidos } from "../../../_common/utils/array/array";
import createSnackBar from "../../../_common/utils/snackbar/createSnackbar";
import * as api from "../../../pages/central-emails/utils/apiCentralEmails";
import ConnectedIntlProvider from "../../../_common/containers/ConnectedIntlProvider";
import store from "../../store";
import { AjaxBlackout } from "../../../_common";

const TamanhoMaximoArquivosAnexos = 8388608;

export const EMAILS__ATUALIZAR_THREADS_RESPONSE = "EMAILS/ATUALIZAR_THREADS_RESPONSE";
export const EMAILS__ATUALIZAR_THREADS_REQUEST = "EMAILS/ATUALIZAR_THREADS_REQUEST";
export const EMAILS__ATUALIZAR_THREADS_ERRO = "EMAILS/ATUALIZAR_THREADS_ERRO";
export const EMAILS__ATUALIZAR_TOTAL_THREADS_RESPONSE = "EMAILS/ATUALIZAR_TOTAL_THREADS_RESPONSE";
export const EMAILS__ATUALIZAR_TOTAL_THREADS_REQUEST = "EMAILS/ATUALIZAR_TOTAL_THREADS_REQUEST";
export const EMAILS__ATUALIZAR_TOTAL_THREADS_ERRO = "EMAILS/ATUALIZAR_TOTAL_THREADS_ERRO";
export const EMAILS__PROVIDER_EMAIL_AUTENTICADO = "EMAILS/PROVIDER_EMAIL_AUTENTICADO";
export const EMAILS__PROVIDER_EMAIL_NAO_AUTENTICADO = "EMAILS/PROVIDER_EMAIL_NAO_AUTENTICADO";
export const EMAILS__AUMENTAR_LIMITE_THREADS = "EMAILS/AUMENTAR_LIMITE_THREADS";
export const EMAILS__RESETAR_LIMITE_THREADS = "EMAILS/RESETAR_LIMITE_THREADS";
export const EMAILS__RESETAR_THREADS = "EMAILS/RESETAR_THREADS";
export const EMAILS__TROCAR_ABA = "EMAILS/TROCAR_ABA";

export const EMAILS__ABRIR_ESCREVER_EMAIL = "EMAILS/ABRIR_ESCREVER_EMAIL";
export const EMAILS__FECHAR_MODAL_ESCREVER_EMAIL = "EMAILS/FECHAR_MODAL_ESCREVER_EMAIL";

export const EMAILS__ABRIR_EDITOR_RESPONDER = "EMAILS/ABRIR_EDITOR_RESPONDER";
export const EMAILS__ABRIR_EDITOR_RESPONDER_REQUEST = "EMAILS/ABRIR_EDITOR_RESPONDER_REQUEST";
export const EMAILS__ABRIR_EDITOR_RESPONDER_RESPONSE = "EMAILS/ABRIR_EDITOR_RESPONDER_RESPONSE";
export const EMAILS__ABRIR_EDITOR_RESPONDER_ERROR = "EMAILS/ABRIR_EDITOR_RESPONDER_ERROR";

export const EMAILS__FECHAR_EDITOR_RESPONDER = "EMAILS/FECHAR_EDITOR_RESPONDER";
export const EMAILS__ABRIR_MODAL_LER_EMAIL_REQUEST = "EMAILS/ABRIR_MODAL_LER_EMAIL_REQUEST";
export const EMAILS__ABRIR_MODAL_LER_EMAIL_RESPONSE = "EMAILS/ABRIR_MODAL_LER_EMAIL_RESPONSE";
export const EMAILS__FECHAR_MODAL_LER_EMAIL = "EMAILS/FECHAR_MODAL_LER_EMAIL";
export const EMAILS__LIMPAR_RESPONDER_EMAILS = "EMAILS/LIMPAR_RESPONDER_EMAILS";

export const EMAILS__ADICIONAR_EMAILTO = "EMAILS/ADICIONAR_EMAILTO";
export const EMAILS__REMOVER_EMAILTO = "EMAILS/REMOVER_EMAILTO";

export const EMAILS__ADICIONAR_EMAILCC = "EMAILS/ADICIONAR_EMAILCC";
export const EMAILS__REMOVER_EMAILCC = "EMAILS/REMOVER_EMAILCC";

export const EMAILS__ADICIONAR_EMAILCCO = "EMAILS/ADICIONAR_EMAILCCO";
export const EMAILS__REMOVER_EMAILCCO = "EMAILS/REMOVER_EMAILCCO";

export const EMAILS__ABRIR_MODAL_ESCOLHER_CONTATO = "EMAILS/ABRIR_MODAL_ESCOLHER_CONTATO";
export const EMAILS__FECHAR_MODAL_ESCOLHER_CONTATO = "EMAILS/FECHAR_MODAL_ESCOLHER_CONTATO";
export const EMAILS__ABRIR_MODAL_SEM_CONTATOS = "EMAILS/ABRIR_MODAL_SEM_CONTATOS";
export const EMAILS__FECHAR_MODAL_SEM_CONTATOS = "EMAILS/FECHAR_MODAL_SEM_CONTATOS";
export const EMAILS__ABRIR_MODAL_ALERTA_VARIAVEIS = "EMAILS/ABRIR_MODAL_ALERTA_VARIAVEIS";
export const EMAILS__FECHAR_MODAL_ALERTA_VARIAVEIS = "EMAILS/FECHAR_MODAL_ALERTA_VARIAVEIS";

export const EMAILS__ABRIR_MODAL_VISUALIZAR_EMAIL = "EMAILS/ABRIR_MODAL_VISUALIZAR_EMAIL";
export const EMAILS__FECHAR_MODAL_VISUALIZAR_EMAIL = "EMAILS/FECHAR_MODAL_VISUALIZAR_EMAIL";

export const EMAILS__ADICIONAR_NEXT_PAGE_TOKEN = "EMAILS/ADICIONAR_NEXT_PAGE_TOKEN";
export const EMAILS__ATUALIZAR_INDICE_PAGE_TOKEN_ATUAL = "EMAILS/ATUALIZAR_INDICE_PAGE_TOKEN_ATUAL";

export const TEMPLATES__BUSCAR_TEMPLATES_SUCESSO = "TEMPLATES/BUSCAR_TEMPLATES_SUCESSO";
export const TEMPLATES__BUSCAR_TEMPLATES_ERRO = "TEMPLATES/BUSCAR_TEMPLATES_ERRO";
export const TEMPLATES__BUSCAR_TEMPLATES_REQUEST = "TEMPLATES/BUSCAR_TEMPLATES_REQUEST";
export const EMAILS__MARCAR_THREAD_LIDA = "EMAILS/MARCAR_THREAD_LIDA";
export const EMAILS__SELECIONAR_TEMPLATE = "EMAILS/SELECIONAR_TEMPLATE";

export const EMAILS__BUSCAR_INFO_TRACKING_REQUEST = "EMAILS/BUSCAR_INFO_TRACKING_REQUEST";
export const EMAILS__BUSCAR_INFO_TRACKING_RESPONSE = "EMAILS/BUSCAR_INFO_TRACKING_RESPONSE";
export const EMAILS__BUSCAR_INFO_TRACKING_ERRO = "EMAILS/BUSCAR_INFO_TRACKING_ERRO";

export const EMAILS__ADICIONAR_ASSINATURA_ENVIO_EMAIL = "EMAILS/ADICIONAR_ASSINATURA_ENVIO_EMAIL";
export const EMAILS__ADICIONAR_LINK_ANEXO = "EMAILS/ADICIONAR_LINK_ANEXO";
export const EMAILS__ATUALIZAR_LINK_ANEXO = "EMAILS/ATUALIZAR_LINK_ANEXO";
export const EMAILS__REMOVER_LINK_ANEXO = "EMAILS/REMOVER_LINK_ANEXO";

export const EMAILS__ABRIR_MODAL_CONFIG = "EMAILS/ABRIR_MODAL_CONFIG";
export const EMAILS__FECHAR_MODAL_CONFIG = "EMAILS/FECHAR_MODAL_CONFIG";

export const EMAILS__ABRIR_MODAL_ASSINATURA = "EMAILS/ABRIR_MODAL_ASSINATURA";
export const EMAILS__FECHAR_MODAL_ASSINATURA = "EMAILS/FECHAR_MODAL_ASSINATURA";

export const EMAILS__BUSCAR_THREADS_REQUEST = "EMAILS/BUSCAR_THREADS_REQUEST";
export const EMAILS__BUSCAR_THREADS_RESPONSE = "EMAILS/BUSCAR_THREADS_RESPONSE";
export const EMAILS__BUSCAR_THREADS_ERROR = "EMAILS/BUSCAR_THREADS_ERROR";

export const EMAILS__INICIAR_BUSCA_THREADS = "EMAILS/INICIAR_BUSCA_THREADS";
export const EMAILS__ALTERAR_BUSCA_THREADS = "EMAILS/ALTERAR_BUSCA_THREADS";
export const EMAILS__FINALIZAR_BUSCA_THREADS = "EMAILS/FINALIZAR_BUSCA_THREADS";

export const EMAILS__ADICIONAR_USUARIO_LOGADO = "EMAILS/ADICIONAR_USUARIO_LOGADO";
export const EMAILS__ABRIR_MODAL_NOVO_TEMPLATE = "EMAILS/ABRIR_MODAL_NOVO_TEMPLATE";
export const EMAILS__FECHAR_MODAL_NOVO_TEMPLATE = "EMAILS/FECHAR_MODAL_NOVO_TEMPLATE";
export const EMAILS__SET_TEMPLATE_ID_MODAL_NOVO_TEMPLATE = "EMAILS/SET_TEMPLATE_ID_MODAL_NOVO_TEMPLATE";

export const EMAILS__SET_READONLY_MODAL_NOVO_TEMPLATE = "EMAILS/SET_READONLY_MODAL_NOVO_TEMPLATE";

export const EMAILS__SET_READONLY_BOTAO_ENVIAR = "EMAILS/SET_READONLY_BOTAO_ENVIAR";

export const EMAILS__ESCONDER_BOTAO_RESPONDER = "EMAILS/SET_ESCONDER_BOTAO_RESPONDER";
export const EMAILS__MARCARLIDO = "EMAILS/MARCARLIDO";
export const EMAILS__CHANGE_FLAG = "EMAILS/CHANGE_FLAG";
export const EMAILS__CHANGE_IMPORTANT = "EMAILS/CHANGE_IMPORTANT";

export const EMAILS__CARREGA_THREAD_NORMALIZADA = "EMAILS__CARREGA_THREAD_NORMALIZADA";
export const EMAILS__ATUALIZAR_MESSAGEID_CONTEUDO_VALUE = "EMAILS__ATUALIZAR_MESSAGEID_CONTEUDO_VALUE";
export const EMAILS__SET_PARAMETROS_EMAIL = "EMAILS__SET_PARAMETROS_EMAIL";

export const atualizarThreadsRequest = () => createAction(EMAILS__ATUALIZAR_THREADS_REQUEST);
export const atualizarThreadsErro = erro => createAction(EMAILS__ATUALIZAR_THREADS_ERRO, erro);
export const atualizarThreadsResponse = (threads, nextPageToken) =>
  createAction(EMAILS__ATUALIZAR_THREADS_RESPONSE, { threads, nextPageToken });
export const atualizarTotalThreadsRequest = () => createAction(EMAILS__ATUALIZAR_TOTAL_THREADS_REQUEST);
export const atualizarTotalThreadsErro = erro => createAction(EMAILS__ATUALIZAR_TOTAL_THREADS_ERRO, { erro });
export const atualizarTotalThreadsResponse = total => createAction(EMAILS__ATUALIZAR_TOTAL_THREADS_RESPONSE, total);
export const aumentarLimiteThreads = () => createAction(EMAILS__AUMENTAR_LIMITE_THREADS);
export const resetarLimiteThreads = () => createAction(EMAILS__RESETAR_LIMITE_THREADS);
export const resetarThreads = () => createAction(EMAILS__RESETAR_THREADS);
export const trocarAba = aba => createAction(EMAILS__TROCAR_ABA, { aba });
export const atualizarIndicePageTokenAtual = indicePageTokenAtual =>
  createAction(EMAILS__ATUALIZAR_INDICE_PAGE_TOKEN_ATUAL, { indicePageTokenAtual });
export const adicionarNextPageToken = nextPageToken =>
  createAction(EMAILS__ADICIONAR_NEXT_PAGE_TOKEN, { nextPageToken });
export const selecionarTemplate = templateId => createAction(EMAILS__SELECIONAR_TEMPLATE, { templateId });

export const providerEmailAutenticado = usuarioLogado =>
  createAction(EMAILS__PROVIDER_EMAIL_AUTENTICADO, usuarioLogado);
export const providerEmailNaoAutenticado = urlAutenticacao =>
  createAction(EMAILS__PROVIDER_EMAIL_NAO_AUTENTICADO, { urlAutenticacao });

export const marcarThreadLida = threadId => createAction(EMAILS__MARCAR_THREAD_LIDA, { threadId });

export const abrirEscreverEmail = () => createAction(EMAILS__ABRIR_ESCREVER_EMAIL);
export const abrirEditorResponderRequest = (emailRespondidoId, isEncaminhando, mensagensIdConteudo, isResponderTodos) =>
  createAction(EMAILS__ABRIR_EDITOR_RESPONDER_REQUEST, {
    emailRespondidoId,
    isEncaminhando,
    mensagensIdConteudo,
    isResponderTodos,
  });
export const abrirEditorResponderResponse = ({ emailsTo, emailsCc, emailsCco }) =>
  createAction(EMAILS__ABRIR_EDITOR_RESPONDER_RESPONSE, {
    emailsTo,
    emailsCc,
    emailsCco,
  });
export const abrirEditorResponderError = error => createAction(EMAILS__ABRIR_EDITOR_RESPONDER, { error });

export const abrirModalLerEmailRequest = thread => createAction(EMAILS__ABRIR_MODAL_LER_EMAIL_REQUEST, { thread });
export const abrirModalLerEmailResponse = dadosParaResposta =>
  createAction(EMAILS__ABRIR_MODAL_LER_EMAIL_RESPONSE, { ...dadosParaResposta });

export const fecharModalLerEmail = () => createAction(EMAILS__FECHAR_MODAL_LER_EMAIL);
export const fecharModalEscreverEmail = () => createAction(EMAILS__FECHAR_MODAL_ESCREVER_EMAIL);
export const limparResponderEmails = () => createAction(EMAILS__LIMPAR_RESPONDER_EMAILS);

export const fecharEditorResponder = () => createAction(EMAILS__FECHAR_EDITOR_RESPONDER);

export const adicionarEmailTo = emailTo => createAction(EMAILS__ADICIONAR_EMAILTO, { emailTo });
export const removerEmailTo = emailTo => createAction(EMAILS__REMOVER_EMAILTO, { emailTo });

export const adicionarEmailCc = emailCc => createAction(EMAILS__ADICIONAR_EMAILCC, { emailCc });
export const removerEmailCc = emailCc => createAction(EMAILS__REMOVER_EMAILCC, { emailCc });

export const adicionarEmailCco = emailCco => createAction(EMAILS__ADICIONAR_EMAILCCO, { emailCco });
export const removerEmailCco = emailCco => createAction(EMAILS__REMOVER_EMAILCCO, { emailCco });

export const abrirModalEscolherContato = listaContatos =>
  createAction(EMAILS__ABRIR_MODAL_ESCOLHER_CONTATO, { listaContatos });
export const fecharModalEscolherContato = () => createAction(EMAILS__FECHAR_MODAL_ESCOLHER_CONTATO);

export const abrirModalSemContatos = () => createAction(EMAILS__ABRIR_MODAL_SEM_CONTATOS);
export const fecharModalSemContatos = () => createAction(EMAILS__FECHAR_MODAL_SEM_CONTATOS);

export const abrirModalVisualizarEmail = threadNormalizada =>
  createAction(EMAILS__ABRIR_MODAL_VISUALIZAR_EMAIL, threadNormalizada);
export const fecharModalVisualizarEmail = () => createAction(EMAILS__FECHAR_MODAL_VISUALIZAR_EMAIL);

export const abrirModalAlertaVariaveis = (listaVariaveis, leadId) =>
  createAction(EMAILS__ABRIR_MODAL_ALERTA_VARIAVEIS, { listaVariaveis, leadId });
export const fecharModalAlertaVariaveis = () => createAction(EMAILS__FECHAR_MODAL_ALERTA_VARIAVEIS);

export const buscarTemplatesSucesso = templates => createAction(TEMPLATES__BUSCAR_TEMPLATES_SUCESSO, templates);
export const buscarTemplatesErro = erro => createAction(TEMPLATES__BUSCAR_TEMPLATES_ERRO, erro);
export const buscarTemplatesRequest = () => createAction(TEMPLATES__BUSCAR_TEMPLATES_REQUEST);

export const buscarTemplatesSelecaoSucesso = templates => createAction(TEMPLATES__BUSCAR_TEMPLATES_SUCESSO, templates);
export const buscarTemplatesSelecaoErro = erro => createAction(TEMPLATES__BUSCAR_TEMPLATES_ERRO, erro);
export const buscarTemplatesSelecaoRequest = () => createAction(TEMPLATES__BUSCAR_TEMPLATES_REQUEST);

export const buscarInfoTrackingRequest = emailId => createAction(EMAILS__BUSCAR_INFO_TRACKING_REQUEST, { emailId });
export const buscarInfoTrackingResponse = (emailId, visualizacoes) =>
  createAction(EMAILS__BUSCAR_INFO_TRACKING_RESPONSE, { emailId, visualizacoes });
export const buscarInfoTrackingErro = erro => createAction(EMAILS__BUSCAR_INFO_TRACKING_ERRO, { erro });

export const adicionarAssinaturaEnvioEmail = assinatura =>
  createAction(EMAILS__ADICIONAR_ASSINATURA_ENVIO_EMAIL, { assinatura });

export const adicionarLinkAnexo = linkAnexo => createAction(EMAILS__ADICIONAR_LINK_ANEXO, { linkAnexo });
export const atualizarLinkAnexo = (chaveBlob, link, base64) =>
  createAction(EMAILS__ATUALIZAR_LINK_ANEXO, { chaveBlob, link, base64 });
export const removerLinkAnexo = (chaveBlob, linksAnexos) => async dispatch => {
  if (linksAnexos.find(x => x.chaveBlob === chaveBlob).completed < 100) {
    createSnackBar(messages.arquivoCarregando);
    return;
  }

  await api.removerLinkAnexo(chaveBlob).then(() => {
    dispatch(createAction(EMAILS__REMOVER_LINK_ANEXO, { chaveBlob }));
  });
};

export const abrirModalConfig = () => createAction(EMAILS__ABRIR_MODAL_CONFIG);
export const fecharModalConfig = () => createAction(EMAILS__FECHAR_MODAL_CONFIG);

export const abrirModalAssinatura = () => createAction(EMAILS__ABRIR_MODAL_ASSINATURA);
export const fecharModalAssinatura = () => createAction(EMAILS__FECHAR_MODAL_ASSINATURA);

export const buscarThreadsRequest = query => createAction(EMAILS__BUSCAR_THREADS_REQUEST, { query });
export const buscarThreadsResponse = () => createAction(EMAILS__BUSCAR_THREADS_RESPONSE);
export const buscarThreadsError = error => createAction(EMAILS__BUSCAR_THREADS_ERROR, { error });

export const iniciarBuscaThreads = () => createAction(EMAILS__INICIAR_BUSCA_THREADS);
export const alterarBuscaThreads = query => createAction(EMAILS__ALTERAR_BUSCA_THREADS, { query });
export const finalizarBuscaThreads = () => createAction(EMAILS__FINALIZAR_BUSCA_THREADS);

export const abrirModalNovoTemplate = () => createAction(EMAILS__ABRIR_MODAL_NOVO_TEMPLATE);
export const fecharModalNovoTemplate = () => createAction(EMAILS__FECHAR_MODAL_NOVO_TEMPLATE);
export const setTemplateIdModalNovoTemplate = id => createAction(EMAILS__SET_TEMPLATE_ID_MODAL_NOVO_TEMPLATE, { id });

export const setReadOnlyModalNovoTemplate = readOnly =>
  createAction(EMAILS__SET_READONLY_MODAL_NOVO_TEMPLATE, { readOnly });

export const buscarThreads = (query, history) => async dispatch => {
  dispatch(buscarThreadsRequest(query));

  if (query) {
    dispatch(trocarAba(BUSCAR));
    dispatch(resetarThreads());
    dispatch(alterarBuscaThreads(query));

    const queryFormatada = query.replace(/%/g, "%25");
    history.push(`/spotter/central-emails/${BUSCAR}?query=${encodeURIComponent(query)}`);
    dispatch(atualizarTotalThreads(queryFormatada));

    try {
      await dispatch(atualizarThreads(queryFormatada));
    } catch (e) {
      dispatch(buscarThreadsError(e));
      throw e;
    }
  } else {
    dispatch(trocarAba(ENTRADA));
    dispatch(resetarThreads());
    history.push(`/spotter/central-emails/${ENTRADA}`);
    dispatch(atualizarThreads());
    dispatch(atualizarTotalThreads());
  }
  dispatch(buscarThreadsResponse());
};

export const atualizarThreads = () => async (dispatch, getState) => {
  dispatch(atualizarThreadsRequest());
  const { abaAtual, indicePageTokenAtual, arrPageToken, busca } = getState().emails;

  const response = await api.buscarThreads(abaAtual, arrPageToken[indicePageTokenAtual], busca.query);
  const { threads, nextPageToken } = response;

  if (nextPageToken && indicePageTokenAtual === arrPageToken.length - 1) {
    dispatch(adicionarNextPageToken(nextPageToken));
  }

  dispatch(atualizarThreadsResponse(threads));

  return threads;
};

export const atualizarTotalThreads = () => async (dispatch, getState) => {
  dispatch(atualizarTotalThreadsRequest());

  const { abaAtual } = getState().emails;
  try {
    if (abaAtual === BUSCAR) {
      dispatch(atualizarTotalThreadsResponse(0));
    } else {
      const total = await api.buscarTotalThreads(abaAtual);
      dispatch(atualizarTotalThreadsResponse(total.data));
    }
  } catch (error) {
    dispatch(atualizarTotalThreadsErro(error));
    throw error;
  }
};

export const buscarTemplates = () => async dispatch => {
  dispatch(buscarTemplatesRequest());

  try {
    const templates = pipe(
      await get("/api/pipeline/TemplateEmail/BuscarTodos"),
      res => objectKeysToCamelCase(res.data)
    );
    dispatch(buscarTemplatesSucesso(templates));
  } catch (erro) {
    dispatch(buscarTemplatesErro(erro));
  }
};

export const buscarTemplatesSelecao = () => async dispatch => {
  dispatch(buscarTemplatesSelecaoRequest());

  try {
    const templates = pipe(
      await get("/api/pipeline/TemplateEmail/BuscarSelecao"),
      res => objectKeysToCamelCase(res.data)
    );
    dispatch(buscarTemplatesSelecaoSucesso(templates));
  } catch (erro) {
    dispatch(buscarTemplatesSelecaoErro(erro));
  }
};

export const avancarPagina = () => async (dispatch, getState) => {
  const { indicePageTokenAtual } = getState().emails;
  dispatch(atualizarIndicePageTokenAtual(indicePageTokenAtual + 1));
  dispatch(resetarThreads());
  dispatch(atualizarThreads());
};

export const paginaAnterior = () => async (dispatch, getState) => {
  const { indicePageTokenAtual } = getState().emails;
  const novoIndicePageToken = indicePageTokenAtual === 0 ? 0 : indicePageTokenAtual - 1;
  dispatch(atualizarIndicePageTokenAtual(novoIndicePageToken));
  dispatch(resetarThreads());
  dispatch(atualizarThreads());
};

export const abrirEditorResponder = (
  emailRespondidoId,
  destinatarios,
  isEncaminhando,
  mensagensIdConteudo,
  isResponderTodos
) => async dispatch => {
  const { emailsTo = [], emailsCc = [], emailsCco = [] } = destinatarios;

  dispatch(abrirEditorResponderRequest(emailRespondidoId, isEncaminhando, mensagensIdConteudo, isResponderTodos));
  const contatos = pipe(
    await Promise.all([prepararListaEmails(emailsTo), prepararListaEmails(emailsCc), prepararListaEmails(emailsCco)]),
    val => ({ emailsTo: uniq(val[0]), emailsCc: uniq(val[1]), emailsCco: uniq(val[2]) })
  );

  dispatch(abrirEditorResponderResponse(contatos));
};

const prepararListaEmails = async emails => {
  if (emails.length === 0) return [];
  const data = await api.buscarContatosComLead(emails.map(x => x.email).join(";"), false);

  const dataCamelCase = objectKeysToCamelCase(data);
  const dataMapped = dataCamelCase.map(mapArrContatosLead);

  const contatosComLeads = dataMapped.map(({ idLead, nomeLead, ...contato }) => {
    const listaLeads = getListaEmpresasContato(dataMapped, contato.email);

    return { ...contato, listaLeads, value: v4() };
  });

  const contatos = emails.map(
    emailTo =>
      contatosComLeads.find(contato => contato.email === emailTo.email) || {
        email: emailTo.email,
        name: emailTo.name === emailTo.email ? emailTo.email.split("@")[0] : emailTo.name,
        id: v4(),
      }
  );

  return contatos;
};

export const abrirModalLerEmail = (thread, readonly, idPublico) => async (dispatch, getState) => {
  dispatch(abrirModalLerEmailRequest(thread));
  const threadNormalizada =
    thread.isIncompleta && !readonly
      ? { ...(await api.buscarPorThreadId(thread.id, thread.folder)), isIncompleta: false }
      : { ...thread, isIncompleta: false };

  const regexTracking = new RegExp(/(<img height=.0. width=.0. src=).(.*?).(>)/g);
  threadNormalizada.body = String(threadNormalizada.body).replace(regexTracking, "$1$3");

  if (!readonly) {
    getThreadComImagensInline(threadNormalizada, threadNormalizada.attachments);
    threadNormalizada.messages.forEach(email => getThreadComImagensInline(email, threadNormalizada.attachments));
  }

  const { emails: emailsState, usuario } = getState();

  const isCadenciaComResposta = thread.isEmailCadencia && thread.threadId !== thread.mensagemId;

  if (thread.isEmailCadencia && !isCadenciaComResposta) {
    dispatch(createAction(EMAILS__ESCONDER_BOTAO_RESPONDER));
  }

  if (!thread.isEmailCadencia && (thread.provider && emailsState.usuarioLogado.provider !== thread.provider)) {
    dispatch(createAction(EMAILS__ESCONDER_BOTAO_RESPONDER));
  }
  const emailList = threadNormalizada.mailRecipents
    ? threadNormalizada.mailRecipents.map(x => x.email).join(";")
    : null;
  const listaContatos = emailList ? await api.buscarContatosComLead(emailList, false, idPublico) : [];

  const dadosResposta = {
    threadNormalizada,
    usuarioLogado: emailsState.usuarioLogado,
    listaLeads: listaContatos,
  };

  dispatch(abrirModalLerEmailResponse(dadosResposta));
  if (!threadNormalizada.isRead && !readonly) {
    api.marcarComoLido(threadNormalizada.id);
    dispatch(createAction(EMAILS__MARCARLIDO, threadNormalizada.id));
  }
  if (emailList) {
    const listaLeads = await api.buscarLeadsPorEmail(emailList);

    if (emailsState.abaAtual === "entrada" && !readonly) {
      await api.salvarMensagemEnviada(threadNormalizada.id, threadNormalizada, usuario, listaLeads);
    }
  }
};

export const EMAILS__ABRIR_MODAL_EXCLUIR_TEMPLATE = "EMAILS/ABRIR_MODAL_EXCLUIR_TEMPLATE";
export const abrirModalExcluirTemplate = templateId =>
  createAction(EMAILS__ABRIR_MODAL_EXCLUIR_TEMPLATE, { templateId });
export const EMAILS__FECHAR_MODAL_EXCLUIR_TEMPLATE = "EMAILS/FECHAR_MODAL_EXCLUIR_TEMPLATE";
export const fecharModalExcluirTemplate = () => createAction(EMAILS__FECHAR_MODAL_EXCLUIR_TEMPLATE);
export const setReadOnlyBotaoEnviar = readonly => createAction(EMAILS__SET_READONLY_BOTAO_ENVIAR, readonly);

/* METODOS QUE ERAM DO PROVIDER */
let canalComunicacaoWebsocket = {};
export const getThreadComImagensInline = (email, attachments) => {
  let conteudo = email.body;
  const placeholderDiv = document.createElement("div");
  placeholderDiv.innerHTML = conteudo;

  const imgNodeList = Array.from(placeholderDiv.querySelectorAll("img") || []);

  imgNodeList.forEach(imgNode => {
    const { src } = imgNode;
    if (!src.startsWith("cid:")) {
      return;
    }

    const cid = src.substr(4);

    const anexo = attachments.find(x => x.cid === cid);
    if (anexo) {
      conteudo = conteudo.replace(`cid:${anexo.cid}`, `data:${anexo.contentType};base64,${anexo.base64Image}`);
    }
  });

  email.bodyOriginal = email.body;
  email.body = conteudo;
};
export const setApiInfo = props => {
  canalComunicacaoWebsocket = props.canalComunicacaoWebsocket;
  api.setApiInfo(props);
};
export const verificaAutenticacao = () => async dispatch => {
  try {
    const usuarioAutenticado = await api.verificaAutenticacao();
    if (usuarioAutenticado.autenticado) {
      dispatch(providerEmailAutenticado(usuarioAutenticado));
    } else {
      dispatch(providerEmailNaoAutenticado());
    }
  } catch (e) {
    dispatch(providerEmailNaoAutenticado());
  }
};

export const excluirEmail = (id, folder) => api.excluirEmail(id, folder);

export const autenticar = async tipoProvider => {
  const result = await api.autenticar(tipoProvider);
  window.location.href = result.data;
};
export const desautenticar = () => async dispatch => {
  await api.desautenticar();
  dispatch(providerEmailNaoAutenticado());
};
export const getIdMensagemUnica = (emailRespostId, thread, messageId) => {
  if (messageId) {
    const threadMessageId = thread.messages.find(x => x.messageId === messageId);
    if (threadMessageId) return [threadMessageId.messageId];

    if (thread.messageId === messageId) return [thread.messageId];
  }

  if (thread.messages) {
    const threadFiltrada = thread.messages.find(x => x.id === emailRespostId);
    if (threadFiltrada) return [threadFiltrada.messageId];
  }

  return [emailRespostId];
};
export const marcarEmailComEstrela = (id, isFlagged) => dispatch => {
  AjaxBlackout.Show();
  api.alterarFlagEmail(id, isFlagged);
  dispatch(createAction(EMAILS__CHANGE_FLAG, { id, isFlagged }));
  AjaxBlackout.Hide();
};
export const marcarEmailImportante = (id, isImportant) => dispatch => {
  AjaxBlackout.Show();
  api.alterarImportant(id, isImportant);
  dispatch(createAction(EMAILS__CHANGE_IMPORTANT, { id, isImportant }));
  AjaxBlackout.Hide();
};
export const getDestinatariosLista = emails => {
  if (emails && Array.isArray(emails)) {
    const destinatarios = emails.reduce(
      (todos, emailAtual) => todos.concat(emailAtual.destinatariosLista, [emailAtual.remetente]),
      []
    );

    const totalizador = [];
    const todosEmails = destinatarios.map(el => el.email);
    const emailsFiltrados = todosEmails.filter(removeRepetidos);

    destinatarios.forEach(usuario => {
      if (emailsFiltrados.some(email => email === usuario.email)) {
        if (!totalizador.some(usuariorepetido => usuariorepetido.email === usuario.email)) {
          totalizador.push(usuario);
        }
      }
    });

    return totalizador;
  }

  return [];
};
export const normalizaDestinatarios = destinatarios => {
  const listaDestinatarios = destinatarios ? destinatarios.split(", ").map(elem => normalizarUsuario(elem)) : [];

  return listaDestinatarios;
};

export const normalizarUsuario = nome => {
  let nomeNormalizado = nome.trim();

  const nomeExtraido = normalizaNome(nomeNormalizado);

  nomeNormalizado = nomeExtraido.nome;
  const email = normalizaEmail(nomeExtraido.resto);

  return { nome: nomeNormalizado, email };
};

const normalizaNome = nome => {
  const rgxAspas = /"(.*)"/;

  const matchAspas = rgxAspas.exec(nome);

  if (matchAspas) {
    const nomeNormalizado = matchAspas[1];
    const resto = nome.replace(rgxAspas, "").trim();

    return {
      nome: nomeNormalizado,
      resto,
    };
  }

  const rgxSemAspas = /(.*?)( <)/;

  const matchSemAspas = rgxSemAspas.exec(nome);

  if (matchSemAspas) {
    const nomeNormalizado = matchSemAspas[1];
    const resto = nome.replace(rgxSemAspas, "$2").trim();

    return {
      nome: nomeNormalizado,
      resto,
    };
  }

  return {
    nome,
    resto: nome,
  };
};

const normalizaEmail = email => {
  const rgx = /<(.*)>/;

  const match = rgx.exec(email);

  if (match) {
    return match[1];
  }

  return email;
};

export const getTextChipContato = (nome, email, nomeLead) => `${nome} (${email}) - ${nomeLead}`;
export const getListaEmpresasContato = (arrContatos, email) => {
  const arrContatosAgrupados = groupBy(arrContatos, "email")[email];
  const arrContatosAgrupadosUnicos = uniqBy(arrContatosAgrupados, "idLead");
  const listaLeads = arrContatosAgrupadosUnicos.map(x => ({
    nome: x.nomeLead,
    id: x.idLead,
  }));
  return listaLeads;
};
export const buscarContatosLead = async (busca, emails, cancelToken) => {
  try {
    const { data } = await api.BuscaContatosLead(busca, cancelToken);
    const dataCamelCase = objectKeysToCamelCase(data);
    const dataMapped = dataCamelCase.map(mapArrContatosLead);
    const arrContatos = emails
      ? differenceWith(dataMapped, emails, (obj1, obj2) => obj1.email === obj2.email)
      : dataMapped;

    return arrContatos;
  } catch (error) {
    if (!isCancel(error)) {
      throw error;
    }
    return null;
  }
};

const mapArrContatosLead = ({ nome, email, nomeLead, ...rest }) => {
  const text = getTextChipContato(nome, email, nomeLead);
  return {
    ...rest,
    name: nome,
    email,
    nomeLead,
    text,
    id: v4(),
  };
};

const messages = defineMessages({
  enviadoComSucesso: {
    defaultMessage: "E-mail enviado com sucesso.",
  },
  arquivoCarregando: {
    defaultMessage: "O carregamento do arquivo ainda está em processamento.",
  },
  semAssunto: {
    defaultMessage: "(sem assunto)",
  },
  tamanhoMaximo: {
    defaultMessage: "Tamanho máximo de arquivos excedido. O limite total de anexos é de 8MB.",
  },
  tipoNaoPermitido: {
    defaultMessage: "Tipo de arquivo não permitido.",
  },
  mensagemEncaminhada: {
    defaultMessage: "Mensagem encaminhada",
  },
  mensagemOriginal: {
    defaultMessage: "Mensagem original",
  },
  data: {
    defaultMessage: "{dia} de {mes} de {ano} {hora}",
  },
  labelDe: {
    defaultMessage: "De",
  },
  labelData: {
    defaultMessage: "Data",
  },
  labelAssunto: {
    defaultMessage: "Assunto",
  },
  labelPara: {
    defaultMessage: "Para",
  },
});

export const enviarEmail = dadosEmail => async dispatch => {
  let exibeModalContatos = false;
  dadosEmail.listaLeads = await api.buscarLeadsPorEmail(dadosEmail.to.join(";"));

  const {
    usuario: { nome, sobrenome },
    emails: {
      usuarioLogado: { email, provider },
    },
  } = store.getState();

  const from = {
    name: `${nome} ${sobrenome}`,
    address: email,
  };

  Object.assign(dadosEmail, { from });

  if (provider === "Outlook") {
    dadosEmail.body += "<br><br>";
  }

  if (!possuiVariaveisParaSubstituir(dadosEmail)) {
    await api.enviarEmail(dadosEmail);
  } else {
    const listaContatos = await api.buscarContatosComLead(dadosEmail.to.join(";"));

    if (listaContatos.length === 0) {
      exibeModalContatos = true;
      dispatch(abrirModalSemContatos());
    } else if (listaContatos.length === 1) {
      exibeModalContatos = await dispatch(
        enviarEmailPreencherContatos(listaContatos[0].idLead, listaContatos[0].email, dadosEmail)
      );
    } else {
      exibeModalContatos = true;
      dispatch(abrirModalEscolherContato(listaContatos));
    }
  }

  AjaxBlackout.Hide();

  if (!exibeModalContatos) {
    createSnackBar(messages.enviadoComSucesso);
    if (dadosEmail.handleFechar) {
      dadosEmail.handleFechar();
    }
  }
};

const trocaVariaveisPorValoresDoLead = (listaDeVariaveis, corpoDoEmail) =>
  Object.keys(listaDeVariaveis).reduce((corpoMensagem, elem) => {
    if (listaDeVariaveis[elem] === null) return corpoMensagem;

    const regexValue = new RegExp(`{{${elem.toString()}}}`, "gi");
    return corpoMensagem.replace(regexValue, listaDeVariaveis[elem]);
  }, corpoDoEmail);
const possuiVariaveisParaSubstituir = dadosEmail => {
  const regex = new RegExp("{{(.*?)}}", "gi");
  const possuiVariavelCorpo = regex.test(dadosEmail.body);
  const possuiVariavelAssunto = regex.test(dadosEmail.subject);

  return possuiVariavelCorpo || possuiVariavelAssunto;
};
const possuiVariaveisCPParaSubstituir = dadosEmail => {
  const regex = new RegExp("{{cp_(.*?)}}", "gi");
  const possuiVariavelCorpo = regex.test(dadosEmail.body);
  const possuiVariavelAssunto = regex.test(dadosEmail.subject);

  return possuiVariavelCorpo || possuiVariavelAssunto;
};

const getVariaveisNaoSubstituidas = corpoDoEmail => {
  const regex = new RegExp("{{(.*?)}}", "gi");

  const lista = [];
  let result = regex.exec(corpoDoEmail);
  for (let i = 0; result != null; i++) {
    lista.push(result[0]);
    result = regex.exec(corpoDoEmail);
  }

  return lista;
};

export const substituiVariaveisTemplate = async (leadId, email, template) => {
  const dados = {
    body: template.conteudo,
    subject: template.assunto,
  };

  if (possuiVariaveisParaSubstituir(dados)) {
    const possuiCP = possuiVariaveisCPParaSubstituir(dados);

    const dadosContato = await api.buscarDadosContato(leadId, email, possuiCP);

    template.conteudo = trocaVariaveisPorValoresDoLead(dadosContato, dados.body);

    if (dados.subject) {
      template.assunto = trocaVariaveisPorValoresDoLead(dadosContato, dados.subject);
    }
  }
};

export const enviarEmailPreencherContatos = (leadId, email, dadosEmail) => async dispatch => {
  const possuiCP = possuiVariaveisCPParaSubstituir(dadosEmail);

  const dadosContato = await api.buscarDadosContato(leadId, email, possuiCP);
  dadosEmail.listaLeads = await api.buscarLeadsPorEmail(dadosEmail.to.join(";"));
  dadosEmail.body = trocaVariaveisPorValoresDoLead(dadosContato, dadosEmail.body);

  if (dadosEmail.subject) {
    dadosEmail.subject = trocaVariaveisPorValoresDoLead(dadosContato, dadosEmail.subject);
    dadosEmail.subjectUT8 = encodeAssunto(trocaVariaveisPorValoresDoLead(dadosContato, dadosEmail.subject) || "");
  }

  const {
    usuario: { nome, sobrenome },
    emails: {
      usuarioLogado: { email: emailUsuario },
    },
  } = store.getState();

  dadosEmail.from = {
    name: `${nome} ${sobrenome}`,
    address: emailUsuario,
  };

  if (!possuiVariaveisParaSubstituir(dadosEmail)) {
    AjaxBlackout.Show();
    await api.enviarEmail(dadosEmail);
    AjaxBlackout.Hide();
    createSnackBar(messages.enviadoComSucesso);
    if (dadosEmail.handleFechar) {
      dadosEmail.handleFechar();
    }
  } else {
    const listaVariaveis = getVariaveisNaoSubstituidas(dadosEmail.body);
    listaVariaveis.push(...getVariaveisNaoSubstituidas(dadosEmail.subject));

    dispatch(abrirModalAlertaVariaveis(uniq(listaVariaveis), leadId));
    return true;
  }
  return false;
};
export const getAliasDefault = () => "";

const convertBase64ToCid = corpo => {
  const placeholderDiv = document.createElement("div");

  placeholderDiv.innerHTML = corpo;

  const arrImagens = Array.from(placeholderDiv.querySelectorAll("img"));
  const base64Rgx = /^data:(.*?);?base64,(.*)$/i;
  let anexosBase64Convertidos = [];

  arrImagens.forEach(img => {
    const match = base64Rgx.exec(img.src);
    if (match) {
      const grupo1 = match[1];
      const grupo2 = match[2];
      const cid = cuid();
      const mimeType = grupo2 ? grupo1 : "image/png";
      const base64 = grupo2 || grupo1;

      img.src = `cid:${cid}`;

      anexosBase64Convertidos = [
        ...anexosBase64Convertidos,
        {
          cid,
          mimeType,
          base64,
          mensagemId: undefined,
          filename: cid,
        },
      ];
    }
  });

  return {
    corpo: placeholderDiv.innerHTML,
    anexosBase64Convertidos,
  };
};
export const getDadosEmail = async (
  {
    emailsTo,
    emailsCc,
    emailsCco,
    corpoEmail,
    assuntoEmail,
    handleFecharModal,
    assinatura,
    mensagensIdConteudo,
    alias,
    isEncaminhando,
    isRespondendo,
    threadNormalizada,
    linksAnexos,
    usuarioSpotter,
    isCadencia,
  },
  intl
) => {
  let conteudoOriginal = assinatura;
  let conteudoExpandido = assinatura;
  let listaAnexos = [];

  if (mensagensIdConteudo) {
    const variaveis = [isEncaminhando, threadNormalizada, mensagensIdConteudo];

    conteudoExpandido += getConteudoEmail(...variaveis, "body", intl);

    conteudoOriginal += getConteudoEmail(...variaveis, "bodyOriginal", intl);
    listaAnexos = threadNormalizada.attachments || [];
    if (threadNormalizada.messages && threadNormalizada.messages.some(x => x.attachments))
      listaAnexos = listaAnexos.concat(threadNormalizada.messages.map(x => x.attachments));

    if (listaAnexos) {
      listaAnexos = listaAnexos.flat().map(x => {
        const anexo = {
          cid: x.cid,
          mimeType: x.contentType,
          base64: x.base64Image,
          mensagemId: x.mensagemId,
          filename: x.name,
        };
        return anexo;
      });
    }
  }

  const { corpo, anexosBase64Convertidos: anexosBase64ConvertidosCorpo } = convertBase64ToCid(corpoEmail);

  const { anexosBase64Convertidos: anexosBase64ConvertidosOriginal } = convertBase64ToCid(conteudoOriginal);
  const { anexosBase64Convertidos: anexosBase64ConvertidosExpandido } = convertBase64ToCid(conteudoExpandido);

  const anexosBase64Convertidos = [
    ...anexosBase64ConvertidosCorpo,
    ...anexosBase64ConvertidosOriginal,
    ...anexosBase64ConvertidosExpandido,
  ];

  if (anexosBase64Convertidos) {
    listaAnexos = [...listaAnexos, ...anexosBase64Convertidos];
  }

  const subject = getSubjectMensagem(assuntoEmail, getPrefixoAssunto(isEncaminhando, isRespondendo));

  let conteudoLinksAnexados = "";
  let headStyleLinks = "";
  if (linksAnexos.length > 0) {
    const sheets = new SheetsRegistry();

    const createGenerateClassName = () => {
      let counter = 0;

      return rule => `emails--${rule.key}-${counter++}`;
    };

    jss.setup({ createGenerateClassName });

    conteudoLinksAnexados += renderToStaticMarkup(
      <Provider store={store}>
        <ConnectedIntlProvider>
          <JssProvider jss={jss} registry={sheets}>
            <AppWrapper>
              <LinksAnexos links={linksAnexos} />
            </AppWrapper>
          </JssProvider>
        </ConnectedIntlProvider>
      </Provider>
    );

    headStyleLinks = `<head><style type="text/css">${sheets.toString()}</style></head>`;
  }
  const corpoFinal = [...headStyleLinks, ...corpo, ...conteudoLinksAnexados].join("");
  const dadosEmail = {
    id: threadNormalizada ? threadNormalizada.id : null,
    messageid: threadNormalizada ? threadNormalizada.messageId : null,
    to: uniq(emailsTo),
    cc: uniq(emailsCc),
    cco: uniq(emailsCco),
    body: corpoFinal,
    bodyOriginal: conteudoExpandido,
    subject,
    subjectUT8: encodeAssunto(subject, intl),
    handleFechar: handleFecharModal,
    listaAnexos,
    linksAnexados: linksAnexos,
    isForwarding: isEncaminhando,
    IsReplaying: isRespondendo,
    alias,
    preVendedorId: usuarioSpotter.id,
    empresaClienteId: usuarioSpotter.empresaClienteId,
    canalComunicacaoWebsocket,
    isCadencia,
  };
  return dadosEmail;
};

const encodeAssunto = (subject, intl) =>
  quotedPrintable
    .encode(utf8.encode(subject || intl.formatMessage({ defaultMessage: "(sem assunto)" })))
    .replace(/=(\r\n|\n|\r)/gm, "");

const getFileBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const tamanhoArquivoValido = size => size <= TamanhoMaximoArquivosAnexos;
const validarTamanhoTotalLinks = (linksAnexos, sizeFile, dispatch) => {
  if (linksAnexos) {
    let total = sumBy(linksAnexos, "size");

    total += sizeFile;
    if (!tamanhoArquivoValido(total)) {
      dispatch(setReadOnlyBotaoEnviar(false));
      createSnackBar(messages.tamanhoMaximo);
      return false;
    }
  }

  return true;
};

export const salvarLinkAnexo = (event, linksAnexos) => async dispatch => {
  dispatch(setReadOnlyBotaoEnviar(true));
  const file = event.target.files[0];

  if (file.type === "application/x-msdownload") {
    dispatch(setReadOnlyBotaoEnviar(false));
    createSnackBar(messages.tipoNaoPermitido);
    return;
  }

  if (!tamanhoArquivoValido(file.size)) {
    dispatch(setReadOnlyBotaoEnviar(false));
    createSnackBar(messages.tamanhoMaximo);
    return;
  }

  if (!validarTamanhoTotalLinks(linksAnexos, file.size, dispatch)) return;

  const chaveBlob = `${moment().valueOf()}-${file.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`;
  const linkAnexo = { chaveBlob, nome: file.name, size: file.size, link: "", completed: 1, base64: "" };
  dispatch(adicionarLinkAnexo(linkAnexo));

  let base64file = await getFileBase64(file);
  base64file = base64file.replace(/\+/g, "-");

  const result = await api.salvarLinkAnexo(chaveBlob, base64file);
  const urlRetorno = result.data;
  dispatch(atualizarLinkAnexo(chaveBlob, urlRetorno, base64file));
};

export const getAssinaturaEmail = async email => {
  const assinatura = await api.retornarAssinatura(email);
  return assinatura.data;
};

const getSeparacaoMensagem = (isEncaminhando, intl) =>
  isEncaminhando
    ? `---------- ${intl.formatMessage(messages.mensagemEncaminhada)} ----------`
    : `---------- ${intl.formatMessage(messages.mensagemOriginal)} ----------`;

const getFimSeparacaoMensagem = () => "----------------------------------------------------";

const getConteudoDataCabecalho = (data, intl) => {
  const conteudo = intl.formatMessage(messages.data, {
    dia: moment(data).format("DD"),
    mes: moment(data).format("MMMM"),
    ano: moment(data).format("YYYY"),
    hora: moment(data).format("HH:mm"),
  });

  return conteudo;
};

const getConteudoComTagFormatada = conteudo =>
  conteudo.replace(new RegExp("<", "g"), "&lt;").replace(new RegExp(">", "g"), "&gt;");

const getConteudoCopiaCabecalho = copia => `CC: ${getConteudoComTagFormatada(copia)}`;

const getCabecalhoConteudoEmail = (threadNormalizada, mensagensIdConteudo, intl) => {
  const mensagemId = [...mensagensIdConteudo].pop();

  let email = threadNormalizada.messages?.find(e => e.messageId === mensagemId);
  if (!email) {
    email = threadNormalizada;
  }

  if (!email) return "";

  const cabecalho = `${intl.formatMessage(messages.labelDe)}: ${email.from.name} &lt;${email.from.email}&gt;<br>
      ${intl.formatMessage(messages.labelData)}: ${getConteudoDataCabecalho(email.date, intl)}<br>
      ${intl.formatMessage(messages.labelAssunto)}: ${email.subject}<br>
      ${intl.formatMessage(messages.labelPara)}: ${email.to.map(x => x.email).join(" ")}<br>
      ${email.cc.length > 0 ? getConteudoCopiaCabecalho(email.cc.map(x => x.email).join(" ")) : ""}<br>`;

  return cabecalho;
};

export const getConteudoEmail = (isEncaminhando, threadNormalizada, mensagensIdConteudo, bodyName, intl) => {
  const separacaoMensagem = getSeparacaoMensagem(isEncaminhando, intl);
  const fimSeparacaoMensagem = getFimSeparacaoMensagem();

  const messageIds = Array.isArray(mensagensIdConteudo) ? mensagensIdConteudo : [mensagensIdConteudo];

  const listaConteudo = messageIds.map(msg => {
    let email = threadNormalizada.messages?.find(e => e.messageId === msg);

    if (!email) {
      email = threadNormalizada;
    }

    if (email) return email[bodyName];
    return "";
  });

  const cabecalhoConteudo = getCabecalhoConteudoEmail(threadNormalizada, messageIds, intl);

  return `${separacaoMensagem}<br>${cabecalhoConteudo}<br><br>${listaConteudo.join("")}${fimSeparacaoMensagem}`;
};

export const getAbasPorProvider = tipoProvider => {
  const abas = api.buscarAbas(tipoProvider);
  return abas;
};

const iconFlagMessages = defineMessages({
  marcarEstrela: {
    defaultMessage: "Marcar com estrela",
  },
  desmarcarEstrela: {
    defaultMessage: "Desmarcar com estrela",
  },
  removerSinalizar: {
    defaultMessage: "Remover sinalizador desta mensagem",
  },
  sinalizar: {
    defaultMessage: "Sinalizar esta mensagem",
  },
});

export const getIconFlag = (tipoProvider, marcado) => {
  switch (PROVIDERS[tipoProvider]) {
    default:
    case PROVIDERS.Gmail: {
      const icon = marcado ? "star" : "star_border";
      const label = marcado ? iconFlagMessages.desmarcarEstrela : iconFlagMessages.marcarEstrela;
      const color = marcado ? "#f4b400" : "#a1a1a1";
      return { icon, color, label };
    }
    case PROVIDERS.Outlook: {
      const icon = marcado ? "flag" : "outlined_flag";
      const label = marcado ? iconFlagMessages.removerSinalizar : iconFlagMessages.sinalizar;
      const color = marcado ? "#f4b400" : "#a1a1a1";
      return { icon, color, label };
    }
  }
};

const iconImportantMessages = defineMessages({
  desmarcar: {
    defaultMessage: "Desmarcar como importante",
  },
  marcar: {
    defaultMessage: "Marcar como importante",
  },
});

export const getIconImportant = (tipoProvider, marcado) => {
  switch (PROVIDERS[tipoProvider]) {
    default:
    case PROVIDERS.Gmail: {
      const icon = marcado ? "label_important" : "label_important_outlined";
      const label = marcado ? iconImportantMessages.desmarcar : iconImportantMessages.marcar;
      const color = marcado ? "#f4b400" : "#a1a1a1";
      return { icon, color, label };
    }
    case PROVIDERS.Outlook: {
      const icon = "priority_high";
      const label = marcado ? iconImportantMessages.desmarcar : iconImportantMessages.marcar;
      const color = marcado ? "#FF2222" : "#a1a1a1";
      return { icon, color, label };
    }
  }
};

export const carregaThreadNormalizada = threadNormalizada =>
  createAction(EMAILS__CARREGA_THREAD_NORMALIZADA, { threadNormalizada });

export const atualizaValorMessageId = mensagensIdConteudo =>
  createAction(EMAILS__ATUALIZAR_MESSAGEID_CONTEUDO_VALUE, { mensagensIdConteudo });

export const setParametrosEmail = parametros => createAction(EMAILS__SET_PARAMETROS_EMAIL, parametros);

const getPrefixoAssunto = (isEncaminhando, isRespondendo) => {
  let prefixo = "";
  if (isRespondendo || isEncaminhando) {
    prefixo = isEncaminhando ? prefixosAssunto.encaminhar : prefixosAssunto.responder;
  }
  return prefixo;
};

const prefixosAssunto = { encaminhar: "Fwd: ", responder: "Re: " };

const getSubjectMensagem = (assunto = "", prefixo) => {
  let assuntoNormalizado = assunto;

  const assuntoPrefixadoResponder = assuntoNormalizado
    .toLowerCase()
    .startsWith(prefixosAssunto.responder.toLowerCase());
  const assuntoPrefixadoEncaminhar = assuntoNormalizado
    .toLowerCase()
    .startsWith(prefixosAssunto.encaminhar.toLowerCase());

  const assuntoJaPossuiPrefixo = assuntoPrefixadoResponder || assuntoPrefixadoEncaminhar;

  if (prefixo && !assuntoJaPossuiPrefixo) {
    assuntoNormalizado = `${prefixo}${assuntoNormalizado}`;
  } else if (prefixo) {
    let replaceContent;

    if (assuntoPrefixadoEncaminhar) {
      replaceContent = prefixosAssunto.encaminhar;
    } else {
      replaceContent = prefixosAssunto.responder;
    }

    assuntoNormalizado = assuntoNormalizado.replace(new RegExp(replaceContent, "i"), prefixo);
  }
  return `${assuntoNormalizado}`;
};
