import Moment from "moment";
import {
  ATIVIDADES__ABRIR_MODAL_ATIVIDADE,
  ATIVIDADES__ABRIR_MODAL_ATIVIDADE_EDITAR_REQUEST,
  ATIVIDADES__ABRIR_MODAL_ATIVIDADE_EDITAR_RESPONSE,
  ATIVIDADES__FECHAR_MODAL_ATIVIDADE,
  ATIVIDADES__BUSCAR_DADOS_MODAL_REQUEST,
  ATIVIDADES__BUSCAR_DADOS_MODAL_RESPONSE,
  ATIVIDADES__BUSCAR_DADOS_MODAL_ERROR,
  ATIVIDADES__HANDLE_CHANGE_TEXTO_PESQUISA_LEAD,
  ATIVIDADES__UPDATE_IS_SEARCH_LEAD,
  ATIVIDADES__CHANGE_LEAD,
  ATIVIDADES__ADD_CONTATO_LEAD,
  ATIVIDADES__DELETE_CONTATO_LEAD,
  ATIVIDADES__CLEAR_CONTATOS_SELECINADOS_LEAD,
  ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_REQUEST,
  ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_RESPONSE,
  ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_ERROR,
  ATIVIDADES__MOSTRAR_MENSAGEM_SUCESSO,
  ATIVIDADES__ABRIR_MODAL_CONFLITOS,
  ATIVIDADES__FECHAR_MODAL_CONFLITOS,
} from "./modalAtividadesActions";

import {
  ATIVIDADES__CARREGAMENTO_INICIAL_REQUEST,
  ATIVIDADES__CARREGAMENTO_INICIAL_RESPONSE,
  ATIVIDADES__LISTAR_ATIVIDADES_REQUEST,
  ATIVIDADES__LISTAR_ATIVIDADES_RESPONSE,
  ATIVIDADES__SELECIONA_TIPO_FILTRO_DATA,
  ATIVIDADES__ALTERAR_DATA_FILTRO,
  ATIVIDADES__ZERAR_PAGINACAO,
  ATIVIDADES__INCREMENTA_PAGINACAO,
  ATIVIDADES__OPEN_MODAL_EXCLUIR,
  ATIVIDADES__CLOSE_MODAL_EXCLUIR,
  ATIVIDADES__HANDLE_ON_CHANGE_NOME_LEAD,
  ATIVIDADES__HANDLE_ON_CLICK_STATUS_ATIVIDADE,
  ATIVIDADES__HANDLE_ON_CLICK_TIPO_ATIVIDADE,
  ATIVIDADES__HANDLE_ON_CHANGE_PRE_VENDEDORES,
  ATIVIDADES__HANDLE_ON_CHANGE_ETAPAS,
  ATIVIDADES__RESET_LISTAS,
  ATIVIDADES__NAO_HA_ITEMS_PARA_LISTAR,
  ATIVIDADES__LEADS_SEM_ATIVIDADES_REQEST,
  ATIVIDADES__LEADS_SEM_ATIVIDADES_RESPONSE,
  ATIVIDADES__CHANGE_ORDENACAO_LEADS_SEM_ATIVIDADE,
  ATIVIDADES__LEADS_SEM_ATIVIDADES_ATUALIZAR_TOTAL,
  ATIVIDADES__ESCONDER_STATUS,
  ATIVIDADES__MOSTRAR_TODOS_STATUS,
} from "./listagemAtividadesActions";

import {
  STATUS_ATRASADAS,
  STATUS_PARA_HOJE,
  STATUS_CONCLUIDAS,
  STATUS_PLANEJADAS,
  COR_FILTRO_ATIVIDADE_ATRASADA,
  COR_FILTRO_ATIVIDADE_CONCLUIDA,
  COR_FILTRO_ATIVIDADE_HOJE,
  COR_FILTRO_ATIVIDADE_PLANEJADA,
} from "../../../pages/atividades/constanteStatusAtividade";

import modalAtividades from "./modalAtividades";
import listagemAtividades from "./listagemAtividades";

export const TIPOS_AGRUPAMENTO = {
  DIA: 0,
  MES: 2,
  ANO: 3,
};

export const returnInitialState = () => ({
  modalAtividade: {
    open: false,
    isLoading: false,
    tiposAtividade: [],
    preVendedores: [],
    lead: {
      id: null,
      nome: "",
      responsavel: {
        id: null,
        descricao: "",
      },
    },
    contatosLead: [],
    contatosLeadSelecionados: [],
    textoPesquisaLead: "",
    isSearchLead: false,
    mostrarMensagemSucesso: false,
    atividadeEdicao: {},
    mensagemAtividade: null,
  },
  modalConflitoAtividade: {
    open: false,
    titulo: "",
    conflitos: [],
    atividade: {},
    tipoValidacao: "",
  },
  filtro: {
    preVendedores: [],
    etapas: [],
    texto: "",
    status: [1, 2, 3],
    tipos: [],
    funis: [],
    agrupamento: TIPOS_AGRUPAMENTO.DIA,
    dataInicio: Moment()
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss"),
    dataFim: Moment()
      .endOf("day")
      .format("YYYY-MM-DD HH:mm:ss"),
  },
  modalExcluir: { aberto: false, id: null },
  modalEditar: { aberto: false, id: null },
  lista: [],
  total: 0,
  listagem: {
    atividadesAgrupada: [],
    totais: [],
    totalLeadsSemAtividades: "",
    isLoading: false,
    isLoadingAtividades: false,
    isLoadingLeadsSemAtividade: false,
    listaEtapas: [],
    listaPvs: [],
    listaTiposAtividade: [],
    filtros: {
      pagina: 0,
      hasMore: true,
      pTipoAgrupamento: 1,
      pDataInicio: new Moment().startOf("day"),
      pDataFim: new Moment().endOf("day"),
      pNomeLead: "",
      pColunaOrdenacao: "ultimaAtividade",
      pTipoOrdenacao: "asc",
    },
    modalExcluir: {
      open: false,
      atividadeId: null,
    },
    listaStatusAtividade: [
      { id: STATUS_ATRASADAS, cor: COR_FILTRO_ATIVIDADE_ATRASADA, tooltip: "Atrasadas", checked: true, mostrar: true },
      { id: STATUS_PARA_HOJE, cor: COR_FILTRO_ATIVIDADE_HOJE, tooltip: "Para hoje", checked: true, mostrar: true },
      {
        id: STATUS_PLANEJADAS,
        cor: COR_FILTRO_ATIVIDADE_PLANEJADA,
        tooltip: "Planejadas",
        checked: true,
        mostrar: true,
      },
      {
        id: STATUS_CONCLUIDAS,
        cor: COR_FILTRO_ATIVIDADE_CONCLUIDA,
        tooltip: "Concluídas",
        checked: false,
        mostrar: true,
      },
    ],
    tipoAtividadeSemRegistro: [
      {
        id: 0,
        nome: "Sem registro de atividades",
        icone: "assignment_late",
        checked: true,
        mostrar: false,
      },
    ],
    leadsSemAtividades: [],
  },
});

const atividades = (state = returnInitialState(), action) => {
  switch (action.type) {
    case "ATIVIDADES/EXCLUIR":
      return { ...state, modalExcluir: action.payload };
    case "ATIVIDADES/EDITAR":
      return { ...state, modalEditar: action.payload /** idAtividade, idLead */ };
    case "ATIVIDADES/SET_FILTRO":
      return { ...state, filtro: { ...state.filtro, ...action.payload } };
    case "ATIVIDADES/SET_LISTA":
      return { ...state, ...action.payload };
    case ATIVIDADES__ABRIR_MODAL_ATIVIDADE:
    case ATIVIDADES__ABRIR_MODAL_ATIVIDADE_EDITAR_REQUEST:
    case ATIVIDADES__ABRIR_MODAL_ATIVIDADE_EDITAR_RESPONSE:
    case ATIVIDADES__FECHAR_MODAL_ATIVIDADE:
    case ATIVIDADES__BUSCAR_DADOS_MODAL_REQUEST:
    case ATIVIDADES__BUSCAR_DADOS_MODAL_RESPONSE:
    case ATIVIDADES__BUSCAR_DADOS_MODAL_ERROR:
    case ATIVIDADES__HANDLE_CHANGE_TEXTO_PESQUISA_LEAD:
    case ATIVIDADES__UPDATE_IS_SEARCH_LEAD:
    case ATIVIDADES__CHANGE_LEAD:
    case ATIVIDADES__ADD_CONTATO_LEAD:
    case ATIVIDADES__DELETE_CONTATO_LEAD:
    case ATIVIDADES__CLEAR_CONTATOS_SELECINADOS_LEAD:
    case ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_REQUEST:
    case ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_RESPONSE:
    case ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_ERROR:
    case ATIVIDADES__MOSTRAR_MENSAGEM_SUCESSO:
    case ATIVIDADES__ABRIR_MODAL_CONFLITOS:
    case ATIVIDADES__FECHAR_MODAL_CONFLITOS:
      return modalAtividades(state, action);
    case ATIVIDADES__LISTAR_ATIVIDADES_REQUEST:
    case ATIVIDADES__LISTAR_ATIVIDADES_RESPONSE:
    case ATIVIDADES__SELECIONA_TIPO_FILTRO_DATA:
    case ATIVIDADES__ALTERAR_DATA_FILTRO:
    case ATIVIDADES__ZERAR_PAGINACAO:
    case ATIVIDADES__INCREMENTA_PAGINACAO:
    case ATIVIDADES__OPEN_MODAL_EXCLUIR:
    case ATIVIDADES__CLOSE_MODAL_EXCLUIR:
    case ATIVIDADES__HANDLE_ON_CHANGE_NOME_LEAD:
    case ATIVIDADES__CARREGAMENTO_INICIAL_REQUEST:
    case ATIVIDADES__CARREGAMENTO_INICIAL_RESPONSE:
    case ATIVIDADES__HANDLE_ON_CLICK_STATUS_ATIVIDADE:
    case ATIVIDADES__HANDLE_ON_CLICK_TIPO_ATIVIDADE:
    case ATIVIDADES__HANDLE_ON_CHANGE_PRE_VENDEDORES:
    case ATIVIDADES__HANDLE_ON_CHANGE_ETAPAS:
    case ATIVIDADES__RESET_LISTAS:
    case ATIVIDADES__NAO_HA_ITEMS_PARA_LISTAR:
    case ATIVIDADES__LEADS_SEM_ATIVIDADES_REQEST:
    case ATIVIDADES__LEADS_SEM_ATIVIDADES_RESPONSE:
    case ATIVIDADES__CHANGE_ORDENACAO_LEADS_SEM_ATIVIDADE:
    case ATIVIDADES__LEADS_SEM_ATIVIDADES_ATUALIZAR_TOTAL:
    case ATIVIDADES__ESCONDER_STATUS:
    case ATIVIDADES__MOSTRAR_TODOS_STATUS:
      return listagemAtividades(state, action);
    default:
      return state;
  }
};

export default atividades;
