import axios from "axios";
import { substituirOcorrencias } from "../../../_common/utils/string";
import store from "../../store";
import CreateSnackBar from "../../../_common/utils/snackbar/createSnackbar";
import {
  setApiInfo,
  verificaAutenticacao,
  abrirEscreverEmail,
  atualizaValorMessageId,
  carregaThreadNormalizada,
  adicionarEmailTo,
  setParametrosEmail,
} from "../emails/emailsActions";
import { AjaxBlackout } from "../../../_common";
import localStorageManager from "../../../_common/utils/storage";
import { JWT_TOKEN } from "../../../_common/utils/storage/constantesStorage";

const variaveisAlterar = {
  "{{lead_contato}}": "{{nome_empresa}}",
  "{{nome_pv}}": "{{nome_prevendedor}}",
  "{{sobrenome_pv}}": "{{sobrenome_prevendedor}}",
};

export async function configurarApiCentralEmails() {
  const { variaveisAmbiente, usuario } = store.getState();

  try {
    setApiInfo({
      UrlSpotterMailService: variaveisAmbiente.listaVariaveis.urlSpotterMailService,
      tokenJwt: localStorageManager.getItem(JWT_TOKEN),
      canalComunicacaoWebsocket: usuario.canalComunicacaoWebsocket,
    });

    await store.dispatch(verificaAutenticacao());
  } catch (error) {
    /* Erro  */
  }
}

export async function responderEmail(
  { campanhaId, etapaId, contatoId } = {},
  { ocultaBotaoVariaveis = false, responderTodos = false, origemAcaoResposta } = {}
) {
  try {
    AjaxBlackout.Show();

    const {
      data: { resposta: respostaEmail },
    } = await axios.get("/api/pipeline/respostas/buscar", {
      params: {
        campanhaId,
        contatoId,
        etapaId,
      },
    });

    if (!respostaEmail) throw new Error("Erro ao carregar informações de e-mail para envio.");

    const emailsCopia = [];
    const emailsTo = [
      {
        id: respostaEmail.contatoEmail,
        name: respostaEmail.contatoNome,
        email: respostaEmail.contatoEmail,
      },
    ];

    if (respostaEmail.emailsCopia) {
      respostaEmail.emailsCopia.split(",").forEach(item => {
        let email = null;
        if (/[<>]/.test(item)) {
          email = item.match(/<(.+)>/)[1];
        } else {
          email = item;
        }

        if (email) {
          emailsCopia.push({ id: email, name: email, email });
        }
      });
    }

    if (responderTodos && emailsCopia.length) {
      emailsTo.push(...emailsCopia.map(({ email }) => ({ id: email, name: email, email })));
    }

    const thread = {
      id: String(respostaEmail.campanhaAcompanhamentoId),
      messageId: respostaEmail.messageId,
      preview: respostaEmail.emailResposta,
      body: respostaEmail.emailResposta,
      subject: substituirOcorrencias(respostaEmail.assuntoTemplate, variaveisAlterar),
      isRead: true,
      isDraft: false,
      isImportant: false,
      isFlagged: false,
      from: { email: respostaEmail.contatoEmail, name: respostaEmail.contatoNome },
      to: [{ email: respostaEmail.preVendedorEmail, name: respostaEmail.preVendedorNome }],
      cc: [],
      cco: [],
      views: null,
      date: respostaEmail.dataResposta,
      messageCount: 0,
      messages: [],
      isSent: false,
      attachments: [],
      provider: null,
      isIncompleta: false,
      bodyOriginal: respostaEmail.emailResposta,
      isResponderTodos: responderTodos,
    };

    store.dispatch(
      setParametrosEmail({
        ocultaBotaoVariaveis,
        permiteUtilizarTemplates: false,
        isCadencia: true,
        marcarEmailRespondido: { campanhaId, etapaId, contatoId, origemAcaoResposta },
      })
    );

    store.dispatch(carregaThreadNormalizada(thread));
    emailsTo.forEach(to => store.dispatch(adicionarEmailTo(to)));
    store.dispatch(atualizaValorMessageId([thread.id]));
    store.dispatch(abrirEscreverEmail());
  } catch ({ message }) {
    CreateSnackBar(message);
  } finally {
    AjaxBlackout.Hide();
  }
}
