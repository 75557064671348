import {
  DETALHES_LEAD__BUSCAR_TIMELINE_ERROR,
  DETALHES_LEAD__BUSCAR_TIMELINE_REQUEST,
  DETALHES_LEAD__BUSCAR_TIMELINE_RESPONSE,
  DETALHES_LEAD__BUSCAR_LEAD_ERROR,
  DETALHES_LEAD__BUSCAR_LEAD_REQUEST,
  DETALHES_LEAD__BUSCAR_LEAD_RESPONSE,
  DETALHES_LEAD__ON_CHANGE_FILTRO_FIXO,
  DETALHES_LEAD__ON_CHANGE_FILTRO_DINAMICO,
  DETALHES_LEAD__OPEN_MODAL_DESCARTE_LEAD_REQUEST,
  DETALHES_LEAD__OPEN_MODAL_DESCARTE_LEAD_RESPONSE,
  DETALHES_LEAD__ATUALIZAR_LISTA_CONTATOS,
  DETALHES_LEAD__ATUALIZAR_LISTA_CONTATOS_BLOQUEADOS,
  DETALHES_LEAD__ATUALIZAR_CONTATO,
  DETALHES_LEAD__VALIDAR_CONTATO,
  DETALHES_LEAD__CLOSE_MODAL_DESCARTE_LEAD,
  DETALHES_LEAD__ATUALIZAR_LEAD,
  DETALHES_LEAD__FLAG_SHOULD_RELOAD_TIMELINE_LOG,
} from "./detalhesLeadActions";

export const returnInitialState = () => ({
  lead: {},
  isLoadingLead: false,
  timeline: {},
  isLoadingTimeline: true,
  modalDescarteLead: {
    open: false,
    isLoading: false,
    motivosDescarte: [],
    leadId: null,
  },
  contatos: [],
  contatosBloqueados: [],
});

const detalhesLead = (state = returnInitialState(), action) => {
  switch (action.type) {
    case DETALHES_LEAD__BUSCAR_TIMELINE_REQUEST:
      return {
        ...state,
        isLoadingTimeline: true,
      };
    case DETALHES_LEAD__BUSCAR_TIMELINE_RESPONSE:
      return {
        ...state,
        logFilterTriggersReload: false,
        isLoadingTimeline: false,
        timeline: {
          ...action.payload,
          filtrosFixos: state.timeline.filtrosFixos || action?.payload?.filtrosFixos,
        },
      };
    case DETALHES_LEAD__BUSCAR_TIMELINE_ERROR:
      return {
        ...state,
        isLoadingTimeline: false,
        timeline: {},
      };
    case DETALHES_LEAD__BUSCAR_LEAD_REQUEST:
      return {
        ...state,
        isLoadingLead: true,
      };
    case DETALHES_LEAD__BUSCAR_LEAD_RESPONSE:
      if (action.payload.Nome) document.title = action.payload.Nome;
      return {
        ...state,
        isLoadingLead: false,
        lead: action.payload,
      };
    case DETALHES_LEAD__BUSCAR_LEAD_ERROR:
      return {
        ...state,
        isLoadingLead: false,
      };
    case DETALHES_LEAD__ON_CHANGE_FILTRO_FIXO: {
      const filtrosFixosAtualizada = state.timeline.filtrosFixos.map(filtro => {
        if (filtro.id.toString() === action.payload) {
          filtro.checked = !filtro.checked;
        }
        return filtro;
      });

      return {
        ...state,
        isLoadingTimeline: false,
        timeline: { ...state.timeline, filtrosFixos: filtrosFixosAtualizada },
      };
    }
    case DETALHES_LEAD__ON_CHANGE_FILTRO_DINAMICO: {
      const filtrosDinamicosAtualizada = state.timeline.filtrosDinamicos.map(filtro => {
        if (filtro.id.toString() === action.payload) {
          filtro.checked = !filtro.checked;
        }
        return filtro;
      });

      return {
        ...state,
        isLoadingTimeline: false,
        timeline: { ...state.timeline, filtrosDinamicos: filtrosDinamicosAtualizada },
      };
    }
    case DETALHES_LEAD__OPEN_MODAL_DESCARTE_LEAD_REQUEST:
      return {
        ...state,
        modalDescarteLead: { ...state.modalDescarteLead, isLoading: true, leadId: action.payload },
      };
    case DETALHES_LEAD__OPEN_MODAL_DESCARTE_LEAD_RESPONSE:
      return {
        ...state,
        modalDescarteLead: {
          ...state.modalDescarteLead,
          open: true,
          isLoading: false,
          motivosDescarte: action.payload,
        },
      };

    case DETALHES_LEAD__ATUALIZAR_LEAD: {
      return {
        ...state,
        empresa: action.payload,
      };
    }

    case DETALHES_LEAD__ATUALIZAR_LISTA_CONTATOS: {
      return {
        ...state,
        contatos: Array.isArray(action.payload) ? action.payload : [],
      };
    }

    case DETALHES_LEAD__ATUALIZAR_LISTA_CONTATOS_BLOQUEADOS: {
      return {
        ...state,
        contatosBloqueados: Array.isArray(action.payload) ? action.payload : [],
      };
    }

    case DETALHES_LEAD__ATUALIZAR_CONTATO: {
      const { id, ...payload } = action.payload;

      return {
        ...state,
        contatos: state.contatos.map(contato => (contato.id === id ? { ...contato, ...payload } : contato)),
      };
    }

    case DETALHES_LEAD__VALIDAR_CONTATO: {
      const { id, ...payload } = action.payload;
      const listaContato = [...state.contatos];

      listaContato.forEach(contato => {
        contato.emails = contato.emails.map(email => (email.id === id ? { ...email, ...payload } : email));
      });

      return {
        ...state,
        contatos: listaContato,
      };
    }

    case DETALHES_LEAD__CLOSE_MODAL_DESCARTE_LEAD:
      return {
        ...state,
        modalDescarteLead: { open: false, isLoading: false, motivosDescarte: [], leadId: null },
      };

    case DETALHES_LEAD__FLAG_SHOULD_RELOAD_TIMELINE_LOG: {
      return {
        ...state,
        timeline: {
          ...state.timeline,
          logFilterTriggersReload: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default detalhesLead;
