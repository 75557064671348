import { selectConfiguracoes } from "../selectors";

export const selectPadrao = state => selectConfiguracoes(state).padrao;
export const selectPadraoModalEdit = state => selectPadrao(state).modalEdit;
export const selectCurrentPadraoDef = state => selectPadrao(state).padraoDef;
export const selectCurrentPadraoType = state => selectCurrentPadraoDef(state).type;
export const selectCurrentPadraoName = state => selectCurrentPadraoDef(state).description.singular;
export const selectCurrentOrigem = state => {
  const { origemSelectInput } = selectPadrao(state);
  const { options, value } = origemSelectInput;
  return options.find(origem => origem.id === value);
};
