import axios from "axios";
import { createSelector } from "reselect";
import { v4 } from "uuid";
import { set } from "dot-prop-immutable";
import { defineMessage } from "react-intl";

import { FeedbackMapper, CommentMapper } from "./mappers";
import { objectKeysToCamelCase } from "../../../_common/utils/string";
import { selectUsuario } from "../usuario/selectors";
import { createAction } from "../../../_common/utils/redux";
import createSnackBar from "../../../_common/utils/snackbar/createSnackbar";
import { AjaxBlackout } from "../../../_common";

const EMPTY_FEEDBACK_DRAFT = () => ({
  commentList: [],
  ratingFields: [],
});

const INITIAL_MODAL_FEEDBACK = () => ({
  leadId: null,
  isOpen: false,
  isTouched: false,
  askedForFeedback: false,
  /**
   * É a versão local do feedback,
   * alterações feitas no backend serão sincronizadas
   * localmente no "draft" para não precisar recarregar o
   * feedback a todo momento
   */
  draft: EMPTY_FEEDBACK_DRAFT(),
});

export const INITIAL_STATE = () => ({
  feedbackLigacao: {
    isLoading: false,
    error: null,
    data: null,
  },
  modalFeedback: INITIAL_MODAL_FEEDBACK(),
});

export default function reducer(state = INITIAL_STATE(), action) {
  switch (action.type) {
    case REQUEST_FEEDBACK: {
      return {
        ...state,
        feedbackLigacao: {
          ...state.feedbackLigacao,
          isLoading: true,
          error: null,
        },
      };
    }
    case RECEIVE_FEEDBACK: {
      const feedbackData = action.payload;
      return {
        ...state,
        feedbackLigacao: {
          isLoading: false,
          error: null,
          data: feedbackData,
        },
        modalFeedback: {
          ...state.modalFeedback,
          draft: FeedbackMapper.toDraftShape(feedbackData),
        },
      };
    }
    case RECEIVE_FEEDBACK_ERROR: {
      const error = action.payload;
      return {
        ...state,
        feedbackLigacao: {
          isLoading: false,
          error,
          data: null,
        },
      };
    }
    case OPEN_MODAL: {
      const { isEdit, leadId } = action.payload;
      return {
        ...state,
        modalFeedback: {
          ...state.modalFeedback,
          isOpen: true,
          leadId,
          isEdit,
        },
      };
    }
    case CLOSE_MODAL: {
      return {
        ...state,
        modalFeedback: INITIAL_MODAL_FEEDBACK(),
      };
    }
    case SET_ASKED_FOR_FEEDBACK: {
      return {
        ...state,
        modalFeedback: {
          ...state.modalFeedback,
          askedForFeedback: true,
        },
      };
    }
    case ON_REMOVE_COMMENT: {
      const id = action.payload;
      return set(state, "modalFeedback.draft.commentList", comments => comments.filter(comment => comment.id !== id));
    }
    case ON_CHANGE_RATING_FIELD: {
      const nextField = action.payload;
      nextField.hasError = nextField.valor <= 0;
      let nextState = set(state, "modalFeedback.draft.ratingFields", fields =>
        fields.map(field => (field.descricaoId === nextField.descricaoId ? nextField : field))
      );
      nextState = set(nextState, "modalFeedback.isTouched", true);
      return nextState;
    }
    case SET_ERROR_RATING_FIELDS: {
      const invalidIds = action.payload;
      return set(state, "modalFeedback.draft.ratingFields", fields =>
        fields.map(field => ({
          ...field,
          hasError: invalidIds.includes(field.descricaoId),
        }))
      );
    }
    case INSERT_NEW_COMMENT: {
      const newComment = action.payload;
      let nextState = set(state, "modalFeedback.draft.commentList", commentList => [newComment, ...commentList]);
      nextState = set(nextState, "modalFeedback.isTouched", true);
      return nextState;
    }
    case ON_INSERT_REPLY: {
      const { targetComment, reply } = action.payload;
      const nextState = set(state, "modalFeedback.isTouched", true);
      return set(nextState, "modalFeedback.draft.commentList", commentList =>
        commentList.map(comment => {
          if (targetComment.id === comment.id) {
            return {
              ...comment,
              replies: Array.isArray(comment.replies) ? [reply, ...comment.replies] : [reply],
              isRead: true,
            };
          }
          return comment;
        })
      );
    }
    case ON_REMOVE_REPLY: {
      const reply = action.payload;
      return set(state, "modalFeedback.draft.commentList", commentList =>
        commentList.map(comment => {
          if (reply.comentarioPaiGuid === comment.guid) {
            return {
              ...comment,
              replies: comment.replies.filter(({ id }) => reply.id !== id),
            };
          }
          return comment;
        })
      );
    }
    case ON_CHECK_COMMENT_AS_READ: {
      const commentId = action.payload;
      const nextState = set(state, "modalFeedback.isTouched", true);
      return set(nextState, "modalFeedback.draft.commentList", commentList =>
        commentList.map(comment => {
          if (comment.id === commentId) {
            return {
              ...comment,
              isRead: true,
            };
          }
          return comment;
        })
      );
    }
    case SOLVE_FEEDBACK: {
      const nextState = set(state, "modalFeedback.isTouched", true);
      return set(nextState, "modalFeedback.draft.canSolveFeedback", false);
    }
    default:
      return state;
  }
}

const REQUEST_FEEDBACK = "FEEDBACK_LIGACAO/REQUEST_FEEDBACK";
const RECEIVE_FEEDBACK = "FEEDBACK_LIGACAO/RECEIVE_FEEDBACK";
const RECEIVE_FEEDBACK_ERROR = "FEEDBACK_LIGACAO/RECEIVE_ERROR_FEEDBACK";
const INSERT_NEW_COMMENT = "FEEDBACK_LIGACAO/INSERT_NEW_COMMENT";
const SET_ASKED_FOR_FEEDBACK = "FEEDBACK_LIGACAO/SET_ASKED_FOR_FEEDBACK";
const SET_ERROR_RATING_FIELDS = "FEEDBACK_LIGACAO/SET_ERROR_RATING_FIELDS";
const ON_REMOVE_COMMENT = "FEEDBACK_LIGACAO/ON_REMOVE_COMMENT";
const OPEN_MODAL = "FEEDBACK_LIGACAO/OPEN_MODAL";
const CLOSE_MODAL = "FEEDBACK_LIGACAO/CLOSE_MODAL";
const ON_CHANGE_RATING_FIELD = "FEEDBACK_LIGACAO/ON_CHANGE_RATING_FIELD";
const ON_INSERT_REPLY = "FEEDBACK_LIGACAO/ON_INSERT_REPLY";
const ON_REMOVE_REPLY = "FEEDBACK_LIGACAO/ON_REMOVE_REPLY";
const ON_CHECK_COMMENT_AS_READ = "FEEDBACK_LIGACAO/ON_CHECK_COMMENT_AS_READ";
const SOLVE_FEEDBACK = "FEEDBACK_LIGACAO/SOLVE_FEEDBACK";

const requestFeedback = () => createAction(REQUEST_FEEDBACK);
const receiveFeedback = data => createAction(RECEIVE_FEEDBACK, data);
const receiveFeedbackError = err => createAction(RECEIVE_FEEDBACK_ERROR, err);
const insertNewComment = newComment => createAction(INSERT_NEW_COMMENT, newComment);
const setAskedForFeedback = () => createAction(SET_ASKED_FOR_FEEDBACK);
const setErrorRatingFields = invalidIds => createAction(SET_ERROR_RATING_FIELDS, invalidIds);
const onInsertReply = (targetComment, reply) => createAction(ON_INSERT_REPLY, { targetComment, reply });
const onRemoveReply = reply => createAction(ON_REMOVE_REPLY, reply);
const onCheckCommentAsRead = commentId => createAction(ON_CHECK_COMMENT_AS_READ, commentId);
const openModalFeedbackLigacao = ({ isEdit, leadId }) => createAction(OPEN_MODAL, { isEdit, leadId });
const solveFeedback = () => createAction(SOLVE_FEEDBACK);
const closeModalFeedbackLigacao = () => createAction(CLOSE_MODAL);

export const onRemoveComment = commentId => createAction(ON_REMOVE_COMMENT, commentId);

export const onChangeRatingField = nextField => createAction(ON_CHANGE_RATING_FIELD, nextField);

export const onOpenModalFeedbackLigacao = ({ feedbackLigacaoId, historicoLigacaoId, leadId }) => async dispatch => {
  dispatch(openModalFeedbackLigacao({ isEdit: !!feedbackLigacaoId, leadId }));
  dispatch(loadFeedbackLigacao(feedbackLigacaoId, historicoLigacaoId));
};

export const onCloseModalFeedbackLigacao = () => (dispatch, getState) => {
  const { isTouched } = selectModalFeedbackLigacao(getState());
  if (isTouched) {
    refreshLists();
  }

  dispatch(closeModalFeedbackLigacao());
};

/**
 * Carrega os dados do Feedback criado ou template do Feedback vazio
 * @param {number} feedbackLigacaoId
 * @param {number} historicoLigacaoId
 */
export const loadFeedbackLigacao = (feedbackLigacaoId, historicoLigacaoId) => async dispatch => {
  const isEdit = !!feedbackLigacaoId;

  try {
    dispatch(requestFeedback());
    const url = isEdit
      ? `/Api/Pipeline/FeedbackLigacao/Modal?feedbackLigacaoId=${feedbackLigacaoId}`
      : `/Api/Pipeline/FeedbackLigacao/Template?historicoLigacaoId=${historicoLigacaoId}`;
    const { data } = await axios.get(url);

    const feedbackData = objectKeysToCamelCase(data);
    dispatch(receiveFeedback(feedbackData));
  } catch (err) {
    dispatch(receiveFeedbackError(err));
    createSnackBar(err.response);
    // eslint-disable-next-line
    console.error(err);
  }
};

export const onInsertComment = ({ time, text, id }, clearInputCallback) => (dispatch, getState) => {
  const { nome, sobrenome, cargo } = selectUsuario(getState());
  const newComment = {
    id: id || v4(),
    time,
    text,
    createdAt: Date.now(),
    author: {
      name: `${nome} ${sobrenome}`,
      role: cargo,
    },
    canRemove: true,
  };

  dispatch(insertNewComment(newComment));
  clearInputCallback();
};

export const onInsertCommentRemote = (comment, clearInputCallback) => async (dispatch, getState) => {
  const feedbackLigacaoId = selectFeedbackData(getState()).id;

  try {
    AjaxBlackout.Show();

    const commentDTO = CommentMapper.toDTOShape(comment);
    commentDTO.feedbackLigacaoId = feedbackLigacaoId;

    const { data } = await axios.post("/Api/Pipeline/FeedbackLigacao/Comentar", commentDTO);
    comment.id = data.id;
    dispatch(onInsertComment(comment, clearInputCallback));
  } catch (err) {
    createSnackBar(defineMessage({ defaultMessage: "Não foi possível inserir o comentário" }));
    // eslint-disable-next-line
    console.error(err);
  } finally {
    AjaxBlackout.Hide();
  }
};

export const onRemoveCommentRemote = id => async dispatch => {
  try {
    AjaxBlackout.Show();

    await axios.delete(`/Api/Pipeline/FeedbackLigacao/ExcluirComentario?comentarioId=${id}`);

    dispatch(onRemoveComment(id));
  } catch (err) {
    createSnackBar(defineMessage({ defaultMessage: "Não foi possível remover o comentário" }));
  } finally {
    AjaxBlackout.Hide();
  }
};

export const onInsertReplyRemote = (targetComment, replyText, clearInputCallback) => async (dispatch, getState) => {
  const { nome, sobrenome, cargo } = selectUsuario(getState());

  const replyDTO = {
    comentarioPaiGuid: targetComment.guid,
    textoComentario: replyText,
    feedbackLigacaoId: selectFeedbackData(getState()).id,
  };

  try {
    AjaxBlackout.Show();

    const { data } = await axios.post("/Api/Pipeline/FeedbackLigacao/Responder", replyDTO);
    const replyDraft = {
      comentarioPaiGuid: targetComment.guid,
      id: data.id,
      guid: data.guid,
      text: replyText,
      author: {
        name: `${nome} ${sobrenome}`,
        role: cargo,
      },
      canRemove: true,
      createdAt: new Date(),
    };
    dispatch(onInsertReply(targetComment, replyDraft));
    clearInputCallback();
  } catch (err) {
    createSnackBar(defineMessage({ defaultMessage: "Não foi possível salvar a resposta." }));
  } finally {
    AjaxBlackout.Hide();
  }
};

export const onRemoveReplyRemote = reply => async dispatch => {
  try {
    AjaxBlackout.Show();

    await axios.delete(`/Api/Pipeline/FeedbackLigacao/ExcluirComentario?comentarioId=${reply.id}`);

    dispatch(onRemoveReply(reply));
  } catch (err) {
    createSnackBar(defineMessage({ defaultMessage: "Não foi possível remover a resposta." }));
  } finally {
    AjaxBlackout.Hide();
  }
};

export const onChangeRatingFieldRemote = nextField => async dispatch => {
  try {
    AjaxBlackout.Show();
    await axios.post("/Api/Pipeline/FeedbackLigacao/ReavaliarCriterio", {
      criterioId: nextField.id,
      estrelas: nextField.valor,
    });
    dispatch(onChangeRatingField(nextField));
  } catch (err) {
    createSnackBar(err.message);
  } finally {
    AjaxBlackout.Hide();
  }
};

const validateRatingFields = () => (dispatch, getState) => {
  const draft = selectFeedbackEditDraft(getState());
  const invalidIds = draft.ratingFields.filter(({ valor }) => valor <= 0).map(({ descricaoId }) => descricaoId);
  if (invalidIds.length > 0) {
    dispatch(setErrorRatingFields(invalidIds));
    return false;
  }
  return true;
};

const validateCommentList = () => (dispatch, getState) => {
  const commentList = selectCommentListEdit(getState());
  return commentList?.length > 0;
};

export const onSaveFeedback = () => async (dispatch, getState) => {
  const draft = selectFeedbackEditDraft(getState());

  if (!dispatch(validateRatingFields())) {
    createSnackBar(defineMessage({ defaultMessage: "Todos os critérios devem ser avaliados." }));
    return;
  }

  if (!dispatch(validateCommentList())) {
    createSnackBar(defineMessage({ defaultMessage: "Deve haver pelo menos um comentário." }));
    return;
  }

  try {
    AjaxBlackout.Show();
    const feedbackDTO = FeedbackMapper.toDTOShape(draft);
    await axios.post("/Api/Pipeline/FeedbackLigacao/Cadastrar", feedbackDTO);

    createSnackBar(defineMessage({ defaultMessage: "Feedback salvo com sucesso." }));
    dispatch(onCloseModalFeedbackLigacao());
    refreshLists();
  } catch (err) {
    createSnackBar(err.message);
  } finally {
    AjaxBlackout.Hide();
  }
};

export const onClickAskForFeedback = () => async (dispatch, getState) => {
  const { historicoLigacaoId } = selectFeedbackData(getState());
  try {
    AjaxBlackout.Show();

    await axios.post(`/Api/Pipeline/FeedbackLigacao/Solicitar?historicoLigacaoId=${historicoLigacaoId}`);

    dispatch(setAskedForFeedback());
    createSnackBar(defineMessage({ defaultMessage: "Pedido de Feedback encaminhado ao seu gestor." }));
  } catch (err) {
    createSnackBar(err.message);
  } finally {
    AjaxBlackout.Hide();
  }
};

export const onCheckCommentAsReadRemote = comment => async dispatch => {
  try {
    AjaxBlackout.Show();

    await axios.post(`/Api/Pipeline/FeedbackLigacao/MarcarComentarioLido?comentarioId=${comment.id}`);

    dispatch(onCheckCommentAsRead(comment.id));
  } catch (err) {
    createSnackBar(err.message);
  } finally {
    AjaxBlackout.Hide();
  }
};

export const solveFeedbackRemote = () => async (dispatch, getState) => {
  const { id } = selectFeedbackData(getState());
  try {
    AjaxBlackout.Show();
    await axios.post(`/Api/Pipeline/FeedbackLigacao/ResolverFeedback?feedback=${id}`);
    dispatch(solveFeedback());
    createSnackBar(defineMessage({ defaultMessage: "Feedback de Ligação resolvido com sucesso." }));
  } catch (err) {
    createSnackBar(err.message);
  } finally {
    AjaxBlackout.Hide();
  }
};

const selectFeedbackLigacaoSlice = state => state.feedbackLigacao;
const selectFeedbackEntity = createSelector([selectFeedbackLigacaoSlice], ({ feedbackLigacao }) => feedbackLigacao);
const selectFeedbackData = createSelector([selectFeedbackEntity], ({ data }) => data);

export const selectFeedbackIsLoading = createSelector([selectFeedbackEntity], ({ isLoading }) => isLoading);
export const selectModalFeedbackLigacao = state => selectFeedbackLigacaoSlice(state).modalFeedback;
export const selectIsOpenModalFeedbackLigacao = createSelector([selectModalFeedbackLigacao], ({ isOpen }) => isOpen);
export const selectFeedbackEditDraft = state => selectModalFeedbackLigacao(state).draft;
export const selectIsEdit = createSelector([selectModalFeedbackLigacao], ({ isEdit }) => !!isEdit);
export const selectFeedbackAudioSource = createSelector([selectFeedbackData], feedback => feedback?.audioSource);
export const selectCanSolveFeedback = createSelector([selectFeedbackEditDraft], draft => draft.canSolveFeedback);
export const selectCanChangeRating = createSelector(
  [selectFeedbackData],
  feedback => feedback?.flPodeReavaliarCriterios
);

export const selectShowAskForFeedback = createSelector(
  [selectIsEdit, selectUsuario, selectFeedbackData],
  (isEdit, { isPreVendedor, id, isVendedor }, data) =>
    !isEdit && (isPreVendedor || isVendedor) && data?.usuarioId === id
);

export const selectAskedForFeedback = createSelector(
  [selectModalFeedbackLigacao, selectFeedbackEditDraft],
  ({ askedForFeedback }, { solicitacaoId }) => askedForFeedback || !!solicitacaoId
);

const MAX_REPLIES_PER_COMMENT = 1;

export const selectCommentListEdit = createSelector(
  [selectUsuario, selectFeedbackEditDraft],
  (currentUser, { commentList, usuarioId }) =>
    commentList.map(comment => {
      const isTargetUser = currentUser.id === usuarioId;
      const canReply = isTargetUser && comment?.replies?.length < MAX_REPLIES_PER_COMMENT;
      const canCheckAsRead = canReply && !comment.isRead;

      return {
        ...comment,
        canReply,
        canCheckAsRead,
      };
    })
);

/**
 * Dispara eventos para recarregr listas que possam ter sua consistência alterada
 * quando o Feedback é editado
 */
function refreshLists() {
  document.dispatchEvent(new CustomEvent("detalhe-lead/timeline/reload"));
  document.dispatchEvent(new CustomEvent("DashboardPV.FeedbacksLigacao.Reload"));
  document.dispatchEvent(new CustomEvent("DashboardGerente.FeedbacksLigacao.Reload"));
}
