import axios from "axios";
import { defineMessage } from "react-intl";
import { createAction } from "../../../../_common/utils/redux";
import createSnackBar from "../../../../_common/utils/snackbar/createSnackbar";
import { selectCurrentPadraoType, selectPadrao } from "./selectors";
import { maybeCallback } from "../../../../_common/utils/fp";
import { PADROES_TYPES } from "../../../../pages/configuracoes/Padroes/constants";
import { AjaxBlackout } from "../../../../_common";
import { getErrorMessage } from "../../../../_common/utils/string";

const MESSAGES = {
  ativado: defineMessage({ defaultMessage: "Padrão ativado com sucesso." }),
  desativado: defineMessage({ defaultMessage: "Padrão desativado com sucesso." }),
};

export const types = {
  REQUEST_DATA: "CONFIGURACOES/PADROES/REQUEST_DATA",
  RECEIVE_DATA_SUCCESS: "CONFIGURACOES/PADROES/RECEIVE_DATA_SUCCESS",
  RECEIVE_DATA_ERROR: "CONFIGURACOES/PADROES/RECEIVE_DATA_ERROR",
  ON_CHANGE_SEARCH_TERM: "CONFIGURACOES/PADROES/ON_CHANGE_SEARCH_TERM",
  SET_PADRAO_DEF: "CONFIGURACOES/PADROES/SET_PADRAO_DEF",
  OPEN_MODAL_EDIT: "CONFIGURACOES/PADROES/OPEN_MODAL_EDIT",
  CLOSE_MODAL_EDIT: "CONFIGURACOES/PADROES/CLOSE_MODAL_EDIT",
  REQUEST_ORIGEM_OPTIONS: "CONFIGURACOES/PADROES/REQUEST_ORIGEM_OPTIONS",
  RECEIVE_ORIGEM_OPTIONS_SUCCESS: "CONFIGURACOES/PADROES/RECEIVE_ORIGEM_OPTIONS_SUCCESS",
  RECEIVE_ORIGEM_OPTIONS_ERROR: "CONFIGURACOES/PADROES/RECEIVE_ORIGEM_OPTIONS_ERROR",
  ON_CHANGE_ORIGEM_SELECT: "CONFIGURACOES/PADROES/ON_CHANGE_ORIGEM_SELECT",
};

export const setPadraoDef = padraoDef => createAction(types.SET_PADRAO_DEF, padraoDef);
export const closeModalEdit = () => createAction(types.CLOSE_MODAL_EDIT);
export const openModalEdit = padrao => createAction(types.OPEN_MODAL_EDIT, padrao);
export const onChangeSearchTerm = value => createAction(types.ON_CHANGE_SEARCH_TERM, value);
export const requestData = () => createAction(types.REQUEST_DATA);
export const receiveDataSuccess = data => createAction(types.RECEIVE_DATA_SUCCESS, data);
export const receiveDataError = () => createAction(types.RECEIVE_DATA_ERROR);
export const requestOrigemOptions = () => createAction(types.REQUEST_ORIGEM_OPTIONS);
export const receiveOrigemOptionsSuccess = data => createAction(types.RECEIVE_ORIGEM_OPTIONS_SUCCESS, data);
export const receiveOrigemOptionsError = () => createAction(types.RECEIVE_ORIGEM_OPTIONS_ERROR);
export const onChangeOrigemSelect = origemId => createAction(types.ON_CHANGE_ORIGEM_SELECT, origemId);

const BASE_URL = padraoType => `/api/pipeline/${padraoType}`;

const padroesAPI = {
  list: padraoType =>
    `${BASE_URL(padraoType)}/Listar?SomenteAtivos=false${
      [PADROES_TYPES.MOTIVO_DESCARTE, PADROES_TYPES.RESULTADO_LIGACAO, PADROES_TYPES.MOTIVO_REUNIAO].includes(
        padraoType
      )
        ? "&traduzirDescricao=true"
        : ""
    }`,
  changeActiveStatus: (padraoType, { id, ativo }) => `${BASE_URL(padraoType)}/MudarStatus?id=${id}&ativo=${ativo}`,
  save: padraoType => `${BASE_URL(padraoType)}/Salvar`,
  remove: (padraoType, padraoId) => `${BASE_URL(padraoType)}/Remover?id=${padraoId}`,
};

export const loadPadroes = () => async (dispatch, getState) => {
  const padraoType = selectCurrentPadraoType(getState());
  dispatch(requestData());
  try {
    const url = padroesAPI.list(padraoType);
    const { data } = await axios.get(url);
    dispatch(receiveDataSuccess(data));
  } catch (e) {
    createSnackBar(e.message);
    dispatch(receiveDataError());
  }
};

const transformPadraoToSave = (padrao, state) => {
  const { isSubOrigem, origemSelectInput } = selectPadrao(state);
  return {
    ...padrao,
    descricao: padrao.descricao.trim(),
    origemId: isSubOrigem ? origemSelectInput.value : null,
  };
};

export const savePadrao = (padrao, callback) => async (dispatch, getState) => {
  const padraoType = selectCurrentPadraoType(getState());
  const url = padroesAPI.save(padraoType);
  const padraoToSave = transformPadraoToSave(padrao, getState());
  try {
    AjaxBlackout.Show();
    await axios.post(url, padraoToSave);
    maybeCallback(callback)(null, padraoToSave);
  } catch (err) {
    maybeCallback(callback)(err, padraoToSave);
  } finally {
    AjaxBlackout.Hide();
  }
};

export const toggleActive = (padrao, intl) => dispatch => {
  const padraoToSave = { ...padrao, ativo: !padrao.ativo };
  const saveCallback = err => {
    if (err) {
      createSnackBar(getErrorMessage(err));
      return;
    }
    dispatch(loadPadroes());
    createSnackBar(intl.formatMessage(padraoToSave.ativo ? MESSAGES.ativado : MESSAGES.desativado));
  };

  dispatch(savePadrao(padraoToSave, saveCallback));
};

export const removePadrao = (padrao, callback) => async (dispatch, getState) => {
  const padraoType = selectCurrentPadraoType(getState());
  const url = padroesAPI.remove(padraoType, padrao.id);
  try {
    AjaxBlackout.Show();
    await axios.post(url);
    maybeCallback(callback)(null, padrao);
  } catch (err) {
    maybeCallback(callback)(err, padrao);
  } finally {
    AjaxBlackout.Hide();
  }
};

export const loadOrigemSelectOptions = () => async dispatch => {
  const url = padroesAPI.list("origem");
  dispatch(requestOrigemOptions());
  try {
    AjaxBlackout.Show();
    const { data } = await axios.get(url);
    dispatch(receiveOrigemOptionsSuccess(data));
  } catch (e) {
    createSnackBar(e.message);
    dispatch(receiveOrigemOptionsError());
  } finally {
    AjaxBlackout.Hide();
  }
};
