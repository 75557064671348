import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import emails from "./emails/emails";
import usuario from "./usuario/usuario";
import dashboard from "./dashboard/dashboard";
import detalhesLead from "./detalhesLead/detalhesLead";
import variaveisAmbiente from "./variaveisAmbiente/variaveisAmbiente";
import atividades from "./atividades/atividades";
import tiposAtividade from "./tiposAtividade/tiposAtividade";
import cadenciaEmails from "../../pages/cadencia-emails/reducer";
import automacao from "./automacao/automacao";
import locale from "./locale/locale";
import baseLeads from "./baseLeads/baseLeads";
import { reducer as flyout } from "../../pages/base-leads/funil/Flyout";
import { reducer as pipeline } from "../../pages/base-leads/pipeline";
import { reducer as filtro } from "../../pages/filtro";
import equipe from "../../pages/equipe/reducer";
import configuracoes from "./configuracoes";
import queryBuilder from "../../_common/components/QueryBuilder/reducer";
import feedbackLigacao from "./feedbackLigacao/reducer";
import whatsApp from "./whatsapp/reducer";
import login from "./login/reducer";

const rootReducer = combineReducers({
  emails,
  usuario,
  dashboard,
  detalhesLead,
  variaveisAmbiente,
  atividades,
  cadenciaEmails,
  tiposAtividade,
  automacao,
  locale,
  baseLeads,
  form,
  flyout,
  pipeline,
  filtro,
  equipe,
  configuracoes,
  queryBuilder,
  feedbackLigacao,
  whatsApp,
  login,
});

export default rootReducer;
