import { LABELS_PERIODO_PADRAO, PERIODO_PADRAO } from "../../../_common/constantes";
import { DASHBOARD__FILTRAR_PERIODO } from "./dashboardActions";

export const returnInitialState = () => ({
  periodo: {
    id: LABELS_PERIODO_PADRAO[PERIODO_PADRAO.SEMANA],
    data: [],
  },
});

const dashboard = (state = returnInitialState(), action) => {
  switch (action.type) {
    case DASHBOARD__FILTRAR_PERIODO: {
      return {
        ...state,
        periodo: {
          data: action.payload.data,
          id: action.payload.id,
        },
      };
    }

    default:
      return state;
  }
};

export default dashboard;
