import axios from "axios";
import Moment from "moment";
import createAction from "../../../_common/utils/redux/createAction";
import { objectKeysToCamelCase } from "../../../_common/utils/string";
import {
  STATUS_PLANEJADAS,
  STATUS_PARA_HOJE,
  STATUS_ATRASADAS,
} from "../../../pages/atividades/constanteStatusAtividade";

export const ATIVIDADES__CARREGAMENTO_INICIAL_REQUEST = "ATIVIDADES/CARREGAMENTO_INICIAL_REQUEST";
export const ATIVIDADES__CARREGAMENTO_INICIAL_RESPONSE = "ATIVIDADES/CARREGAMENTO_INICIAL_RESPONSE";

export const ATIVIDADES__LISTAR_ATIVIDADES_REQUEST = "ATIVIDADES/LISTAR_ATIVIDADES_REQUEST";
export const ATIVIDADES__LISTAR_ATIVIDADES_RESPONSE = "ATIVIDADES/LISTAR_ATIVIDADES_RESPONSE";

export const ATIVIDADES__SELECIONA_TIPO_FILTRO_DATA = "ATIVIDADES/SELECIONA_TIPO_FILTRO_DATA";
export const ATIVIDADES__ALTERAR_DATA_FILTRO = "ATIVIDADES/ALTERAR_DATA_FILTRO";

export const ATIVIDADES__ZERAR_PAGINACAO = "ATIVIDADES/ZERAR_PAGINACAO";
export const ATIVIDADES__INCREMENTA_PAGINACAO = "ATIVIDADES/INCREMENTA_PAGINACAO";

export const ATIVIDADES__OPEN_MODAL_EXCLUIR = "ATIVIDADES/OPEN_MODAL_EXCLUIR";
export const ATIVIDADES__CLOSE_MODAL_EXCLUIR = "ATIVIDADES/CLOSE_MODAL_EXCLUIR";

export const ATIVIDADES__HANDLE_ON_CHANGE_NOME_LEAD = "ATIVIDADES/HANDLE_ON_CHANGE_NOME_LEAD";
export const ATIVIDADES__HANDLE_ON_CLICK_STATUS_ATIVIDADE = "ATIVIDADES/HANDLE_ON_CLICK_STATUS_ATIVIDADE";
export const ATIVIDADES__HANDLE_ON_CLICK_TIPO_ATIVIDADE = "ATIVIDADES/HANDLE_ON_CLICK_TIPO_ATIVIDADE";
export const ATIVIDADES__HANDLE_ON_CHANGE_PRE_VENDEDORES = "ATIVIDADES/HANDLE_ON_CHANGE_PRE_VENDEDORES";
export const ATIVIDADES__HANDLE_ON_CHANGE_ETAPAS = "ATIVIDADES/HANDLE_ON_CHANGE_ETAPAS";

export const ATIVIDADES__RESET_LISTAS = "ATIVIDADES/RESET_LISTAS";
export const ATIVIDADES__NAO_HA_ITEMS_PARA_LISTAR = "ATIVIDADES/NAO_HA_ITEMS_PARA_LISTAR";

export const ATIVIDADES__LEADS_SEM_ATIVIDADES_REQEST = "ATIVIDADES/LEADS_SEM_ATIVIDADES_REQEST";
export const ATIVIDADES__LEADS_SEM_ATIVIDADES_RESPONSE = "ATIVIDADES/LEADS_SEM_ATIVIDADES_RESPONSE";
export const ATIVIDADES__LEADS_SEM_ATIVIDADES_ATUALIZAR_TOTAL = "ATIVIDADES/LEADS_SEM_ATIVIDADES_ATUALIZAR_TOTAL";

export const ATIVIDADES__CHANGE_ORDENACAO_LEADS_SEM_ATIVIDADE = "ATIVIDADES/CHANGE_ORDENACAO_LEADS_SEM_ATIVIDADE";

export const carregamentoInicialResquest = () => createAction(ATIVIDADES__CARREGAMENTO_INICIAL_REQUEST);
export const carregamentoInicialResponse = data => createAction(ATIVIDADES__CARREGAMENTO_INICIAL_RESPONSE, data);

export const listarAtividadesResquest = () => createAction(ATIVIDADES__LISTAR_ATIVIDADES_REQUEST);
export const listarAtividadesResponse = atividadesAgrupada =>
  createAction(ATIVIDADES__LISTAR_ATIVIDADES_RESPONSE, atividadesAgrupada);

export const selecionaTipoFiltroData = ({ pTipoAgrupamento, pTipoAgrupamentoDesc }) =>
  createAction(ATIVIDADES__SELECIONA_TIPO_FILTRO_DATA, { pTipoAgrupamento, pTipoAgrupamentoDesc });
export const alterarData = ({ periodo, descricaoTipo }) =>
  createAction(ATIVIDADES__ALTERAR_DATA_FILTRO, { periodo, descricaoTipo });

export const handleZerarPaginacao = () => createAction(ATIVIDADES__ZERAR_PAGINACAO);
export const incrementaPaginacao = () => createAction(ATIVIDADES__INCREMENTA_PAGINACAO);

export const handleOpenModalExcluir = atividadeId => createAction(ATIVIDADES__OPEN_MODAL_EXCLUIR, atividadeId);
export const handleCloseModalExcluir = () => createAction(ATIVIDADES__CLOSE_MODAL_EXCLUIR);

export const handleOnChangeNomeLead = value => createAction(ATIVIDADES__HANDLE_ON_CHANGE_NOME_LEAD, value);

export const handleOnClickStatusAtividade = idStatus =>
  createAction(ATIVIDADES__HANDLE_ON_CLICK_STATUS_ATIVIDADE, idStatus);

export const handleOnClickTipoAtividade = idTipoAtividade =>
  createAction(ATIVIDADES__HANDLE_ON_CLICK_TIPO_ATIVIDADE, idTipoAtividade);

export const handleChangePreVendedores = values => createAction(ATIVIDADES__HANDLE_ON_CHANGE_PRE_VENDEDORES, values);
export const handleChangeEtapas = values => createAction(ATIVIDADES__HANDLE_ON_CHANGE_ETAPAS, values);

export const handleResetListas = () => createAction(ATIVIDADES__RESET_LISTAS);
export const handleNaoHaItemsParaListar = () => createAction(ATIVIDADES__NAO_HA_ITEMS_PARA_LISTAR);

export const listarLeadsSemAtividadesRequest = () => createAction(ATIVIDADES__LEADS_SEM_ATIVIDADES_REQEST);
export const listarLeadsSemAtividadesResponse = leads => createAction(ATIVIDADES__LEADS_SEM_ATIVIDADES_RESPONSE, leads);
export const atualizarTotalLeadsSemAtividade = total =>
  createAction(ATIVIDADES__LEADS_SEM_ATIVIDADES_ATUALIZAR_TOTAL, total);

export const handleChangeOrdenacaoLeadsSemAtividade = colunaOrdenacao =>
  createAction(ATIVIDADES__CHANGE_ORDENACAO_LEADS_SEM_ATIVIDADE, colunaOrdenacao);

export const handleCarregamentoInicial = () => async dispatch => {
  dispatch(carregamentoInicialResquest());

  const resp = await axios.get("/NovoAtividades/CarregamentoInicial");

  dispatch(carregamentoInicialResponse(objectKeysToCamelCase(resp.data)));
};

let axiosSource = null;
export const handleListarAtividades = zerarPaginacao => async (dispatch, getState) => {
  if (axiosSource) {
    axiosSource.cancel();
  }

  const state = getState();

  if (!validaFiltrosPreenchidos(state, "atividades")) {
    dispatch(handleNaoHaItemsParaListar());
  } else if (state.atividades.listagem.filtros.hasMore || zerarPaginacao) {
    dispatch(listarAtividadesResquest());

    if (zerarPaginacao) {
      dispatch(handleZerarPaginacao());
    }

    dispatch(incrementaPaginacao());

    const filtros = montaFiltros(getState(), "atividades");

    const cancelToken = axios.CancelToken;
    axiosSource = cancelToken.source();

    const resp = await axios.get("/NovoAtividades/ListarAtividades", {
      cancelToken: axiosSource.token,
      params: filtros,
    });

    const atividadesAgrupada = objectKeysToCamelCase(resp.data);

    dispatch(listarAtividadesResponse(atividadesAgrupada));
  }
};

const montaFiltros = (state, tipoFiltro) => {
  const { filtros, listaStatusAtividade, listaTiposAtividade, listaPvs, listaEtapas } = state.atividades.listagem;

  let filtro = {};

  if (tipoFiltro === "atividades") {
    filtro = {
      pTipoAgrupamento: filtros.pTipoAgrupamento,
      pDataInicio: new Moment(filtros.pDataInicio).format("YYYY-MM-DD"),
      pDataFim: new Moment(filtros.pDataFim).format("YYYY-MM-DD"),
    };

    if (listaStatusAtividade) {
      filtro.pStatus = listaStatusAtividade
        .filter(status => status.checked)
        .map(status => status.id)
        .join(",");
    }
  }

  if (tipoFiltro === "leadsSemAtividade") {
    filtro = {
      pColunaOrdenacao: filtros.pColunaOrdenacao,
      pTipoOrdenacao: filtros.pTipoOrdenacao,
    };
  }

  filtro.pagina = filtros.pagina;

  if (filtros.pNomeLead) {
    filtro.pNomeLead = filtros.pNomeLead;
  }

  if (listaTiposAtividade) {
    filtro.pTiposAtividade = listaTiposAtividade
      .filter(tipoAtividade => tipoAtividade.checked && tipoAtividade.mostrar)
      .map(tipoAtividade => tipoAtividade.id)
      .join(",");
  }

  if (listaPvs) {
    filtro.pListaPvs = listaPvs
      .filter(preVendedor => preVendedor.checked)
      .map(preVendedor => preVendedor.id)
      .join(",");
  }

  if (listaEtapas) {
    filtro.pListaEtapas = listaEtapas
      .filter(etapa => etapa.checked)
      .map(etapa => etapa.id)
      .join(",");
  }

  return filtro;
};

const validaFiltrosPreenchidos = (state, tipoFiltro) => {
  const { listaStatusAtividade, listaTiposAtividade, listaPvs, listaEtapas } = state.atividades.listagem;

  const nenhumStatusSelecionado = listaStatusAtividade.filter(status => status.mostrar && status.checked).length === 0;

  if (tipoFiltro === "atividades" && nenhumStatusSelecionado) {
    return false;
  }

  const nenhumTipoAtividadeSelecionado =
    listaTiposAtividade.filter(tipoAtividade => tipoAtividade.checked && tipoAtividade.mostrar).length === 0;
  const nenhumPreVendedorSelecionado = listaPvs && listaPvs.filter(preVendedor => preVendedor.checked).length === 0;
  const nenhumaEtapaSelecionada = listaEtapas.filter(etapa => etapa.checked).length === 0;

  if (nenhumTipoAtividadeSelecionado || nenhumPreVendedorSelecionado || nenhumaEtapaSelecionada) {
    return false;
  }
  return true;
};

export const handleSelecionaTipoFiltroData = (pTipoAgrupamento, pTipoAgrupamentoDesc) => (dispatch, getState) => {
  dispatch(selecionaTipoFiltroData({ pTipoAgrupamento, pTipoAgrupamentoDesc }));
  dispatch(handleListarAtividades(true));
  dispatch(handleControlarVisibilidadeBotoesStatus(getState()));
};

export const handleAlterarData = (periodo, descricaoTipo) => (dispatch, getState) => {
  dispatch(alterarData({ periodo, descricaoTipo }));
  dispatch(handleListarAtividades(true));
  dispatch(handleControlarVisibilidadeBotoesStatus(getState()));
};

export const handleListarLeadsSemAtividades = zerarPaginacao => async (dispatch, getState) => {
  if (axiosSource) {
    axiosSource.cancel();
  }

  if (!validaFiltrosPreenchidos(getState(), "leadsSemAtividade")) {
    dispatch(handleNaoHaItemsParaListar());
  } else {
    dispatch(listarLeadsSemAtividadesRequest());

    if (zerarPaginacao) {
      dispatch(handleZerarPaginacao());
    }

    dispatch(incrementaPaginacao());
    const filtros = montaFiltros(getState(), "leadsSemAtividade");

    const cancelToken = axios.CancelToken;
    axiosSource = cancelToken.source();

    const resp = await axios.get("/NovoAtividades/ListarLeadSemAtividades", {
      cancelToken: axiosSource.token,
      params: filtros,
    });

    const leads = objectKeysToCamelCase(resp.data);

    dispatch(listarLeadsSemAtividadesResponse(leads));
  }
};

export const handleAtualizarTotalLeadsSemAtividade = () => async dispatch => {
  const resp = await axios.get("/NovoAtividades/TotalLeadsSemAtividades");

  dispatch(atualizarTotalLeadsSemAtividade(resp.data));
};

export const handleControlarVisibilidadeBotoesStatus = state => async (dispatch, getState) => {
  const stateAtividade = state || getState();

  const { pDataInicio, pDataFim } = stateAtividade.atividades.listagem.filtros;

  dispatch(handleMostrarTodosStatus());

  if (pDataInicio.format("L") === pDataFim.format("L") && pDataInicio.format("L") === new Moment().format("L")) {
    dispatch(handleEsconderStatus(STATUS_PLANEJADAS));
  }
  if (!new Moment().isBetween(pDataInicio, pDataFim)) {
    dispatch(handleEsconderStatus(STATUS_PARA_HOJE));
  }
  if (pDataInicio > new Moment()) {
    dispatch(handleEsconderStatus(STATUS_ATRASADAS));
  }
  if (pDataFim < new Moment()) {
    dispatch(handleEsconderStatus(STATUS_PLANEJADAS));
  }
};

export const ATIVIDADES__ESCONDER_STATUS = "ATIVIDADES/ESCONDER_STATUS";
export const handleEsconderStatus = status => createAction(ATIVIDADES__ESCONDER_STATUS, status);
export const ATIVIDADES__MOSTRAR_TODOS_STATUS = "ATIVIDADES/MOSTRAR_TODOS_STATUS";
export const handleMostrarTodosStatus = () => createAction(ATIVIDADES__MOSTRAR_TODOS_STATUS);
