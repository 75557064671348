import axios from "axios";

import createAction from "../../../_common/utils/redux/createAction";
import { objectKeysToCamelCase } from "../../../_common/utils/string";

export const AUTOMACAO__LISTAR_CONFIGURACOES = "AUTOMACAO/LISTARCONFIGURACOES";

export const recuperarItens = () => async dispatch => {
  const resp = await axios.get("/Automacao/ListarAutomacoes");
  dispatch(createAction(AUTOMACAO__LISTAR_CONFIGURACOES, objectKeysToCamelCase(resp.data.items)));
};
