import axios from "axios";
import createAction from "../../../_common/utils/redux/createAction";
import { resolvePromisesIdentificadas } from "../../../_common/utils/promise";
import { objectKeysToCamelCase } from "../../../_common/utils/string";
import createSnackBar from "../../../_common/utils/snackbar/createSnackbar";
import { AjaxBlackout } from "../../../_common";

export const ATIVIDADES__ABRIR_MODAL_ATIVIDADE = "ATIVIDADES/ABRIR_MODAL_ATIVIDADE";
export const ATIVIDADES__ABRIR_MODAL_ATIVIDADE_EDITAR_REQUEST = "ATIVIDADES/ABRIR_MODAL_ATIVIDADE_EDITAR_REQUEST";
export const ATIVIDADES__ABRIR_MODAL_ATIVIDADE_EDITAR_RESPONSE = "ATIVIDADES/ABRIR_MODAL_ATIVIDADE_EDITAR_RESPONSE";
export const ATIVIDADES__FECHAR_MODAL_ATIVIDADE = "ATIVIDADES/FECHAR_MODAL_ATIVIDADE";

export const ATIVIDADES__BUSCAR_DADOS_MODAL_REQUEST = "ATIVIDADES/BUSCAR_DADOS_MODAL_REQUEST";
export const ATIVIDADES__BUSCAR_DADOS_MODAL_RESPONSE = "ATIVIDADES/BUSCAR_DADOS_MODAL_RESPONSE";
export const ATIVIDADES__BUSCAR_DADOS_MODAL_ERROR = "ATIVIDADES/BUSCAR_DADOS_MODAL_ERROR";

export const ATIVIDADES__HANDLE_CHANGE_TEXTO_PESQUISA_LEAD = "ATIVIDADES/HANDLE_CHANGE_TEXTO_PESQUISA_LEAD";
export const ATIVIDADES__UPDATE_IS_SEARCH_LEAD = "ATIVIDADES/UPDATE_IS_SEARCH_LEAD";
export const ATIVIDADES__CHANGE_LEAD = "ATIVIDADES/CHANGE_LEAD";

export const ATIVIDADES__ADD_CONTATO_LEAD = "ATIVIDADES/ADD_CONTATO_LEAD";
export const ATIVIDADES__DELETE_CONTATO_LEAD = "ATIVIDADES/DELETE_CONTATO_LEAD";
export const ATIVIDADES__CLEAR_CONTATOS_SELECINADOS_LEAD = "ATIVIDADES/CLEAR_CONTATOS_SELECINADOS_LEAD";

export const ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_REQUEST =
  "ATIVIDADES/BUSCAR_CONTATOS_RESPONSAVEL_LEAD_REQUEST";
export const ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_RESPONSE =
  "ATIVIDADES/BUSCAR_CONTATOS_RESPONSAVEL_LEAD_RESPONSE";
export const ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_ERROR = "ATIVIDADES/BUSCAR_CONTATOS_RESPONSAVEL_LEAD_ERROR";

export const ATIVIDADES__MOSTRAR_MENSAGEM_SUCESSO = "ATIVIDADES/MOSTRAR_MENSAGEM_SUCESSO";

export const ATIVIDADES__ABRIR_MODAL_CONFLITOS = "ATIVIDADES/ABRIR_MODAL_CONFLITOS";
export const ATIVIDADES__FECHAR_MODAL_CONFLITOS = "ATIVIDADES/FECHAR_MODAL_CONFLITOS";

export const abrirModalAtividade = lead => createAction(ATIVIDADES__ABRIR_MODAL_ATIVIDADE, lead);
export const abrirModalAtividadeEditarRequest = () => createAction(ATIVIDADES__ABRIR_MODAL_ATIVIDADE_EDITAR_REQUEST);
export const abrirModalAtividadeEditarResponse = atividade =>
  createAction(ATIVIDADES__ABRIR_MODAL_ATIVIDADE_EDITAR_RESPONSE, atividade);
export const handleFecharModalAtividade = () => createAction(ATIVIDADES__FECHAR_MODAL_ATIVIDADE);

export const buscarDadosModalAtividadeResquest = () => createAction(ATIVIDADES__BUSCAR_DADOS_MODAL_REQUEST);
export const buscarDadosModalAtividadeResponse = resp => createAction(ATIVIDADES__BUSCAR_DADOS_MODAL_RESPONSE, resp);
export const buscarDadosModalAtividadeError = error => createAction(ATIVIDADES__BUSCAR_DADOS_MODAL_ERROR, error);

export const handleChangeTextoPesquisaLead = value =>
  createAction(ATIVIDADES__HANDLE_CHANGE_TEXTO_PESQUISA_LEAD, value);
export const handleUpdateIsSearchLead = status => createAction(ATIVIDADES__UPDATE_IS_SEARCH_LEAD, status);
export const handleChangeLead = lead => createAction(ATIVIDADES__CHANGE_LEAD, lead);

export const handleAddContatoLead = contato => createAction(ATIVIDADES__ADD_CONTATO_LEAD, contato);
export const handleDeleteContatoLead = key => createAction(ATIVIDADES__DELETE_CONTATO_LEAD, key);
export const handleClearContatosSelecionadosLead = () => createAction(ATIVIDADES__CLEAR_CONTATOS_SELECINADOS_LEAD);

export const buscarContatosEResponsavelLeadResquest = () =>
  createAction(ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_REQUEST);
export const buscarContatosEResponsavelLeadResponse = resp =>
  createAction(ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_RESPONSE, resp);
export const buscarContatosEResponsavelLeadError = error =>
  createAction(ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_ERROR, error);
export const mostrarMensagemSucesso = mensagemAtividade =>
  createAction(ATIVIDADES__MOSTRAR_MENSAGEM_SUCESSO, mensagemAtividade);

export const handleAbrirModalAtividade = (lead, mensagemSucesso, mensagemAtividade) => dispatch => {
  dispatch(handleBuscarDadosModalAtividade(lead));
  dispatch(abrirModalAtividade(lead));

  if (mensagemSucesso) {
    dispatch(mostrarMensagemSucesso(mensagemAtividade));
  }
};

export const handleAbrirModalAtividadeEditar = atividadeId => async dispatch => {
  dispatch(abrirModalAtividadeEditarRequest());

  const resp = await axios.get(`/api/pipeline/atividade/buscar?id=${atividadeId}`).catch(() => {
    AjaxBlackout.Hide();
    return Promise.reject();
  });

  if (resp.data) {
    const atividade = objectKeysToCamelCase(resp.data);

    dispatch(abrirModalAtividadeEditarResponse(atividade));
    dispatch(handleBuscarDadosModalAtividade(atividade.lead));
    dispatch(abrirModalAtividade(atividade.lead));
  } else {
    AjaxBlackout.Hide();
  }
};

export const handleBuscarDadosModalAtividade = lead => async (dispatch, getState) => {
  try {
    dispatch(buscarDadosModalAtividadeResquest());

    const state = getState();
    const tasks = {
      taskTiposAtividade: axios.get(`/api/pipeline/TipoAtividade/Listar`),
    };

    if (state.usuario.isGerente) {
      tasks.taskPreVendedores = axios.get(`/api/pipeline/usuario1/listar?tipo=PreVendedor`);
    }

    if (lead && lead.id) {
      tasks.taskDadosLead = axios.get(`/api/pipeline/contatos/listar?leadId=${lead.id}`);
    }

    const taskResolved = await resolvePromisesIdentificadas(tasks);
    const { taskTiposAtividade, taskPreVendedores, taskDadosLead } = taskResolved;

    const payload = {
      tiposAtividade: taskTiposAtividade.data,
      preVendedores: taskPreVendedores ? taskPreVendedores.data : [],
      contatosLead: taskDadosLead ? taskDadosLead.data : [],
    };

    dispatch(buscarDadosModalAtividadeResponse(payload));
  } catch (error) {
    AjaxBlackout.Hide();
    dispatch(buscarDadosModalAtividadeError(error));
  }
};

export const handleBuscarContatosEResponsavelLead = leadId => async dispatch => {
  try {
    dispatch(buscarContatosEResponsavelLeadResquest());

    const { data } = await axios.get("/NovoAtividades/RetornaContatosEResponsavelLead", { params: { leadId } });
    dispatch(buscarContatosEResponsavelLeadResponse(data));
  } catch (error) {
    dispatch(buscarContatosEResponsavelLeadError(error));
  }
};

export const handleAbrirModalConflitos = dadosModal => createAction(ATIVIDADES__ABRIR_MODAL_CONFLITOS, dadosModal);
export const handleFecharModalConflitos = () => createAction(ATIVIDADES__FECHAR_MODAL_CONFLITOS);

/**
 * CONCLUIR ATIVIDADE
 * Conclui a atividade e abre a modal de criação para criar uma nova atividade.
 * @param {Object} atividade Dados relacionados à Atividade
 */
export const handleConcluirAtividade = (atividade, callback) => async () => {
  AjaxBlackout.Show();

  const { data: success } = await axios.post(`/api/pipeline/atividade/concluir?id=${atividade.id}`);

  if (success) createSnackBar("Operação efetuada com sucesso!");
  AjaxBlackout.Hide();
  if (callback) callback(success);
};
