import { types } from "./actions";
import { NONE, PADROES_TYPES, getPadraoDef } from "../../../../pages/configuracoes/Padroes/constants";
import { filterByTermInProps } from "../../../../_common/utils/fp";

const defaultPadrao = () => ({
  id: null,
  descricao: "",
  ativo: true,
});

export const INITIAL_STATE = {
  data: [],
  filteredData: [],
  isLoading: false,
  searchTerm: "",

  padraoDef: getPadraoDef(PADROES_TYPES.ORIGEM), // default
  modalEdit: {
    isOpen: false,
    padrao: defaultPadrao(),
  },
  isSubOrigem: false,
  isProduto: false,
  origemSelectInput: {
    isLoading: false,
    value: NONE,
    options: [],
  },
};

const filterByDescricao = searchTerm => filterByTermInProps(searchTerm, ["descricao"]);

export default function padraoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_PADRAO_DEF: {
      const padraoDef = action.payload;
      return {
        ...state,
        searchTerm: "",
        padraoDef,
        isOrigem: padraoDef.type === PADROES_TYPES.ORIGEM,
        isSubOrigem: padraoDef.type === PADROES_TYPES.SUB_ORIGEM,
        isProduto: padraoDef.type === PADROES_TYPES.PRODUTO,
        origemSelectInput: {
          ...state.origemSelectInput,
          value: NONE,
        },
      };
    }
    case types.REQUEST_DATA: {
      return {
        ...state,
        isLoading: true,
        data: [],
        filteredData: [],
      };
    }
    case types.RECEIVE_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
    }
    case types.RECEIVE_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
        data: [],
        filteredData: [],
      };
    }
    case types.REQUEST_ORIGEM_OPTIONS: {
      return {
        ...state,
        origemSelectInput: {
          ...state.origemSelectInput,
          isLoading: true,
          options: [],
        },
      };
    }
    case types.RECEIVE_ORIGEM_OPTIONS_SUCCESS: {
      return {
        ...state,
        origemSelectInput: {
          ...state.origemSelectInput,
          isLoading: false,
          options: action.payload,
        },
      };
    }
    case types.RECEIVE_ORIGEM_OPTIONS_ERROR: {
      return {
        ...state,
        origemSelectInput: {
          ...state.origemSelectInput,
          isLoading: false,
          options: [],
        },
      };
    }
    case types.ON_CHANGE_ORIGEM_SELECT: {
      const origemId = action.payload;
      return {
        ...state,
        origemSelectInput: {
          ...state.origemSelectInput,
          value: origemId,
        },
      };
    }
    case types.ON_CHANGE_SEARCH_TERM: {
      const searchTerm = action.payload;
      const shouldFilter = !!searchTerm && !!searchTerm.trim();
      return {
        ...state,
        filteredData: shouldFilter ? filterByDescricao(searchTerm)(state.data) : state.data,
        searchTerm: action.payload,
      };
    }
    case types.OPEN_MODAL_EDIT: {
      return {
        ...state,
        modalEdit: {
          isOpen: true,
          padrao: action.payload || defaultPadrao(),
        },
      };
    }
    case types.CLOSE_MODAL_EDIT: {
      return {
        ...state,
        modalEdit: {
          ...state.modalEdit,
          isOpen: false,
        },
      };
    }
    default:
      return state;
  }
}
