import { set } from "dot-prop-immutable";
import { AUTOMACAO__LISTAR_CONFIGURACOES } from "./automacaoActions";

const returnInitialState = () => ({
  items: [],
});

const automacao = (state = returnInitialState(), action) => {
  switch (action.type) {
    case AUTOMACAO__LISTAR_CONFIGURACOES:
      return set(state, "items", action.payload);
    default:
      return {
        ...state,
      };
  }
};

export default automacao;
