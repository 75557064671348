import axios from "axios";
import createAction from "../../../_common/utils/redux/createAction";
import { objectKeysToCamelCase, toUpperCamel } from "../../../_common/utils/string/string";
import { TOTAL_LEADS_POR_PAGINA } from "../../../pages/base-leads/constantesBaseLead";

export const ACTIONS = {
  BUSCAR_FASES_LEAD_REQUEST: "BASE_LEADS/BUSCAR_FASES_LEAD_REQUEST",
  BUSCAR_FASES_LEAD_RESPONSE: "BASE_LEADS/BUSCAR_FASES_LEAD_RESPONSE",
  BUSCAR_LEADS_RESPONSE: "BASE_LEADS/BUSCAR_LEADS_RESPONSE",
  ATUALIZAR_CONTADOR_LEAD: "BASE_LEADS/ATUALIZAR_CONTADOR_LEAD",
  INCREMENTAR_PARINACAO: "BASE_LEADS/INCREMENTAR_PARINACAO",
  ZERAR_PARINACAO: "BASE_LEADS/ZERAR_PARINACAO",
  PARAR_PARINACAO: "BASE_LEADS/PARAR_PARINACAO",
  ABRIR_MODAL_CANCELAR_REUNIAO: "BASE_LEADS/ABRIR_MODAL_CANCELAR_REUNIAO",
  FECHAR_MODAL_CANCELAR_REUNIAO: "BASE_LEADS/FECHAR_MODAL_CANCELAR_REUNIAO",
  CANCELAR_REUNIAO: "BASE_LEADS/CANCELAR_REUNIAO",
  DESCARTAR_LEAD: "BASE_LEADS/DESCARTAR_LEAD",
  ALTERAR_STATUS_ATIVIDADE: "BASE_LEADS/ALTERAR_STATUS_ATIVIDADE",
  ALTERAR_TIPO_ATIVIDADE: "BASE_LEADS/ALTERAR_TIPO_ATIVIDADE",
  CHANGE_CAMPO_BUSCA: "BASE_LEADS/CHANGE_CAMPO_BUSCA",
  ABRIR_MODAL_BUSCA_AVANCADA: "BASE_LEADS/ABRIR_MODAL_BUSCA_AVANCADA",
  FECHAR_MODAL_BUSCA_AVANCADA: "BASE_LEADS/FECHAR_MODAL_BUSCA_AVANCADA",
  GET_LISTAGENS_BUSCA_AVANCADA_REQUEST: "BASE_LEADS/GET_LISTAGENS_BUSCA_AVANCADA_REQUEST",
  GET_LISTAGENS_BUSCA_AVANCADA_RESPONSE: "BASE_LEADS/GET_LISTAGENS_BUSCA_AVANCADA_RESPONSE",
  ATUALIZAR_LISTAGENS: "BASE_LEADS/ATUALIZAR_LISTAGENS",
  ATUALIZAR_FILTROS_BUSCA_AVANCADA: "BASE_LEADS/ATUALIZAR_FILTROS_BUSCA_AVANCADA",
  LIMPAR_BUSCA_AVANCADA: "BASE_LEADS/LIMPAR_BUSCA_AVANCADA",
  ATIVAR_MODO_BUSCA_AVANCADA: "BASE_LEADS/ATIVAR_MODO_BUSCA_AVANCADA",
  MUDAR_ORDENACAO: "BASE_LEADS/MUDAR_ORDENACAO",
  RENOMEAR_ETAPA: "BASE_LEADS/RENOMEAR_ETAPA",
  RENOMEAR_FUNIL: "BASE_LEADS/RENOMEAR_FUNIL",
  FILTRAR_ETAPA: "BASE_LEADS/FILTRAR_ETAPA",
};

// #region Buscar Fases Lead
const buscarFasesLeadRequest = () => createAction(ACTIONS.BUSCAR_FASES_LEAD_REQUEST);
const buscarFasesLeadResponse = ({ etapas, funil }, tiposAtividade, tiposAtividadePaginada) =>
  createAction(ACTIONS.BUSCAR_FASES_LEAD_RESPONSE, { etapas, funil, tiposAtividade, tiposAtividadePaginada });

export const buscarFasesLead = () => async dispatch => {
  dispatch(buscarFasesLeadRequest());
  const resp = await axios.get("/api/pipeline/Funil/Buscar");
  const { etapas, ...funil } = objectKeysToCamelCase(resp.data);
  etapas
    .map(({ id, ordenacao: { campo, sentido } }) => ({ id, campo, sentido }))
    .forEach(({ id, campo, sentido }) => dispatch(createAction(ACTIONS.MUDAR_ORDENACAO, { id, campo, sentido })));
  dispatch(buscarFasesLeadResponse({ etapas, funil }));
};
// #endregion

// #region Paginação
const incrementarPaginacao = faseLeadId => createAction(ACTIONS.INCREMENTAR_PARINACAO, { faseLeadId });
const zerarPaginacao = faseLeadId => createAction(ACTIONS.ZERAR_PARINACAO, { faseLeadId });
const pararPaginacao = faseLeadId => createAction(ACTIONS.PARAR_PARINACAO, { faseLeadId });
// #endregion

// #region Buscar Leads
const buscarLeadsResponse = (leads, faseLeadId) => createAction(ACTIONS.BUSCAR_LEADS_RESPONSE, { leads, faseLeadId });
const atualizarContadorLeads = (count, faseLeadId) =>
  createAction(ACTIONS.ATUALIZAR_CONTADOR_LEAD, { count, faseLeadId });

const axiosSourcesBuscarLead = {};
export const buscarLeads = (faseLeadId, gate, zeraPaginacao, funilId) => async (dispatch, getState) => {
  if (zeraPaginacao) dispatch(zerarPaginacao(faseLeadId));
  dispatch(incrementarPaginacao(faseLeadId));

  const src = axiosSourcesBuscarLead[faseLeadId];
  if (src) src.cancel();
  axiosSourcesBuscarLead[faseLeadId] = axios.CancelToken.source();

  const { ordenacao, pagina, arrStatusAtividade, campoBusca } = getFiltrosBasicos(faseLeadId, getState());
  let { filtros } = getState().baseLeads.modals.buscaAvancada;
  filtros = filtros || {};
  filtros.statusAtividades = arrStatusAtividade
    .split(",")
    .filter(Boolean)
    .map(el => parseInt(el, 10));

  const resp = await axios.post(
    "/api/pipeline/Lead/Pesquisar",
    {
      EtapaId: faseLeadId,
      GateAnterior: gate,
      Pagina: pagina - 1,
      Ordenacao: toUpperCamel(ordenacao),
      Filtros: {
        ...toUpperCamel(filtros),
        EnderecoParcial: filtros.enderecoContem,
        Texto: campoBusca,
        Listagens: undefined,
        TodosEstados: undefined,
        TodasCidades: undefined,
        funis: [funilId],
      },
    },
    {
      cancelToken: axiosSourcesBuscarLead[faseLeadId].token,
    }
  );

  const data = objectKeysToCamelCase(resp.data);
  const { leads, count } = data;

  if (leads.length < TOTAL_LEADS_POR_PAGINA) {
    dispatch(pararPaginacao(faseLeadId));
  }

  if (count !== null) {
    dispatch(atualizarContadorLeads(count, faseLeadId));
  }

  dispatch(buscarLeadsResponse(leads, faseLeadId));
};

const getFiltrosBasicos = (faseLeadId, state) => {
  const { fasesLead, listagens, campoBusca, ordenacao } = state.baseLeads;
  const indexFaseLead = fasesLead.findIndex(fase => fase.id === faseLeadId);
  const faseLead = fasesLead[indexFaseLead];

  let arrStatusAtividade = "";
  if (listagens.statusAtividade.some(status => !status.checked)) {
    arrStatusAtividade = listagens.statusAtividade
      .filter(status => status.checked)
      .map(status => status.id)
      .join(",");
  }

  return { pagina: faseLead.pagina, arrStatusAtividade, campoBusca, ordenacao: ordenacao[faseLead.id] };
};
// #endregion

// #region Busca Avançada
export const abrirModalBuscaAvancada = () => createAction(ACTIONS.ABRIR_MODAL_BUSCA_AVANCADA);
export const fecharModalBuscaAvancada = () => createAction(ACTIONS.FECHAR_MODAL_BUSCA_AVANCADA);

const getListagensBuscaAvancadaRequest = () => createAction(ACTIONS.GET_LISTAGENS_BUSCA_AVANCADA_REQUEST);
const getListagensBuscaAvancadaResponse = listagens =>
  createAction(ACTIONS.GET_LISTAGENS_BUSCA_AVANCADA_RESPONSE, { listagens });

export const handleAtualizarListagens = listagens => createAction(ACTIONS.ATUALIZAR_LISTAGENS, { listagens });

export const getListagensBuscaAvancada = () => async (dispatch, getState) => {
  const { isLoadingListagens } = getState().baseLeads;
  if (isLoadingListagens !== false) {
    dispatch(getListagensBuscaAvancadaRequest());

    const { data } = await axios.get("/api/pipeline/Funil/ListarBuscaAvancada?id=1");

    dispatch(getListagensBuscaAvancadaResponse(data));
  }
};

export const handleAtivarModoBuscaAvancada = () => createAction(ACTIONS.ATIVAR_MODO_BUSCA_AVANCADA);

export const buscaAvancadaLeads = (faseLeadId, tipoFase, zeraPaginacao) => async (dispatch, getState) => {
  if (zeraPaginacao) {
    dispatch(zerarPaginacao(faseLeadId));
  }

  dispatch(incrementarPaginacao(faseLeadId));

  const { filtros } = getState().baseLeads.modals.buscaAvancada;
  const { listagens, ...filtrosAvancados } = filtros;
  const filtrosBuscaAvanda = {
    ...filtrosAvancados,
    faseLeadId,
    tipoFase,
    ...getFiltrosBasicos(faseLeadId, getState()),
  };

  const resp = await axios.post("/api/pipeline/Lead/Pesquisar", filtrosBuscaAvanda);
  const data = objectKeysToCamelCase(resp.data);
  const { leads, count } = data;

  if (leads.length < TOTAL_LEADS_POR_PAGINA) {
    dispatch(pararPaginacao(faseLeadId));
  }

  if (count !== null) {
    dispatch(atualizarContadorLeads(count, faseLeadId));
  }

  dispatch(buscarLeadsResponse(leads, faseLeadId));
};

const limparBuscaAvancada = () => createAction(ACTIONS.LIMPAR_BUSCA_AVANCADA);
export const handleLimparBuscaAvancada = () => dispatch => {
  dispatch(limparBuscaAvancada());
  dispatch(fecharModalBuscaAvancada());
};

const atualizarFitrosBuscaAcancada = filtros => createAction(ACTIONS.ATUALIZAR_FILTROS_BUSCA_AVANCADA, { filtros });
export const handleAtualizarFitrosBuscaAcancada = filtros => dispatch => {
  dispatch(atualizarFitrosBuscaAcancada({ ...filtros }));
};
// #endregion

// #region Change Filtros
export const handleChangeStatusAtividade = idStatus => async dispatch => {
  dispatch(createAction(ACTIONS.ALTERAR_STATUS_ATIVIDADE, { idStatus }));
};

export const handleChangeCampoBusca = campoBusca => dispatch => {
  dispatch(createAction(ACTIONS.CHANGE_CAMPO_BUSCA, { campoBusca }));
};
// #endregion

// #region Ordenação
export const mudarOrdenacao = ({ campo, id }) => async (dispatch, getState) => {
  const {
    baseLeads: { ordenacao },
  } = getState();
  const sentido = ordenacao[id].campo === campo && ordenacao[id].sentido === "Asc" ? "Desc" : "Asc";
  // axios.post("/api/pipeline/Usuario/Ordenacao", toUpperCamel({ etapaId: id, campo, sentido }));
  dispatch(createAction(ACTIONS.MUDAR_ORDENACAO, { campo, sentido, id }));
};
// #endregion

export const renomearEtapa = ({ id, nome }) => async dispatch => {
  await axios.post("/api/pipeline/Etapa/Renomear", { id, nome });
  dispatch(createAction(ACTIONS.RENOMEAR_ETAPA, { id, nome }));
};

export const renomearFunil = ({ id, nome }) => async dispatch => {
  await axios.post("/api/pipeline/Funil/Renomear", { id, nome });
  dispatch(createAction(ACTIONS.RENOMEAR_FUNIL, nome));
};

// #region Cancelar Reunião
export const abrirModalCancelarReuniao = leadId => createAction(ACTIONS.ABRIR_MODAL_CANCELAR_REUNIAO, { leadId });
export const fecharModalCancelarReuniao = () => createAction(ACTIONS.FECHAR_MODAL_CANCELAR_REUNIAO);
export const cancelarReuniao = cardLead => createAction(ACTIONS.CANCELAR_REUNIAO, { cardLead });
// #endregion

// #region Descartar Lead
export const descartarLead = leadId => createAction(ACTIONS.DESCARTAR_LEAD, { leadId });
// #endregion

export const filtrarEtapa = (id, filtros) => createAction(ACTIONS.FILTRAR_ETAPA, { id, filtros });
export const toggleAgendamento = (id, value) => (dispatch, getState) => {
  const {
    baseLeads: {
      filtrosPorEtapa: { [id]: filtros },
    },
  } = getState();
  dispatch(
    createAction(ACTIONS.FILTRAR_ETAPA, {
      id,
      filtros: { ...filtros, tipoAgendamento: value === filtros.tipoAgendamento ? null : value },
    })
  );
};
