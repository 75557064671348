import { get, set, merge, delete as dotPropDelete } from "dot-prop-immutable";

import { ACTIONS } from "./baseLeadsActions";

import { LISTA_STATUS_ATIVIDADE, TIPO_FASE } from "../../../pages/base-leads/constantesBaseLead";

const initialFaseLead = {
  leads: [],
  isLoading: true,
  hasMore: true,
  pagina: 0,
};

const initialListagens = {
  isLoading: null,
  statusAtividade: LISTA_STATUS_ATIVIDADE,
  tiposAtividade: [],
  mercados: [],
  origens: [],
  subOrigens: [],
  paises: [],
  estados: [],
  cidades: [],
  preVendedores: [],
  vendedores: [],
  dores: [],
  produtos: [],
  camposPersonalizados: [],
};

const returnInitialState = () => ({
  isLoading: null,
  isLoadingListagens: null,
  fasesLead: [],
  modals: {
    buscaAvancada: {
      open: false,
      filtros: null,
    },
    cancelarReuniao: {
      open: false,
      leadId: null,
    },
  },
  listagens: initialListagens,
  funil: { nome: "", id: 1, default: true },
  campoBusca: "",
  ordenacao: {}, // { [id: number]: { campo: string, sentido: 'asc'|'desc'} }
  filtrosPorEtapa: {}, // { [id: number]: { agendamento: number[] } }
  modoBuscaAvancada: false,
  filtroTipoAtividade: {
    totalPaginas: null,
    todosItens: [],
    pagina: null,
    listaVisivel: [],
  },
});

const BaseLeads = (state = returnInitialState(), action) => {
  switch (action.type) {
    case ACTIONS.BUSCAR_FASES_LEAD_REQUEST:
      return set(state, "isLoading", true);
    case ACTIONS.BUSCAR_FASES_LEAD_RESPONSE: {
      let newState = set(state, "isLoading", false);
      newState = set(newState, "funil", action.payload.funil);
      return set(newState, "etapas", action.payload.etapas.map(etapa => ({ ...etapa, ...initialFaseLead })));
    }
    case ACTIONS.ZERAR_PARINACAO: {
      const { faseLeadId } = action.payload;
      const indexFaseLead = state.fasesLead.findIndex(fase => fase.id === faseLeadId);

      let newState = set(state, `fasesLead.${indexFaseLead}.leads`, []);
      newState = set(newState, `fasesLead.${indexFaseLead}.hasMore`, true);
      return set(newState, `fasesLead.${indexFaseLead}.pagina`, 0);
    }
    case ACTIONS.INCREMENTAR_PARINACAO: {
      const { faseLeadId } = action.payload;
      const indexFaseLead = state.fasesLead.findIndex(fase => fase.id === faseLeadId);

      const newState = set(state, `fasesLead.${indexFaseLead}.isLoading`, true);
      return set(newState, `fasesLead.${indexFaseLead}.pagina`, state.fasesLead[indexFaseLead].pagina + 1);
    }
    case ACTIONS.PARAR_PARINACAO: {
      const { faseLeadId } = action.payload;
      const indexFaseLead = state.fasesLead.findIndex(fase => fase.id === faseLeadId);

      const newState = set(state, `fasesLead.${indexFaseLead}.isLoading`, false);
      return set(newState, `fasesLead.${indexFaseLead}.hasMore`, false);
    }
    case ACTIONS.BUSCAR_LEADS_RESPONSE: {
      const { leads, faseLeadId } = action.payload;
      const indexFaseLead = state.fasesLead.findIndex(fase => fase.id === faseLeadId);

      const newState = set(state, `fasesLead.${indexFaseLead}.isLoading`, false);
      return merge(newState, `fasesLead.${indexFaseLead}.leads`, leads);
    }
    case ACTIONS.ATUALIZAR_CONTADOR_LEAD: {
      const { count, faseLeadId } = action.payload;
      const indexFaseLead = state.fasesLead.findIndex(fase => fase.id === faseLeadId);

      return set(state, `fasesLead.${indexFaseLead}.totalLeads`, count);
    }
    case ACTIONS.ABRIR_MODAL_BUSCA_AVANCADA:
      return set(state, "modals.buscaAvancada.open", true);
    case ACTIONS.FECHAR_MODAL_BUSCA_AVANCADA:
      return set(state, "modals.buscaAvancada.open", false);
    case ACTIONS.ALTERAR_STATUS_ATIVIDADE: {
      const status = state.listagens.statusAtividade.find(item => item.id === parseInt(action.payload.idStatus, 0));
      const indexStatus = state.listagens.statusAtividade.findIndex(
        item => item.id === parseInt(action.payload.idStatus, 0)
      );

      return set(state, `listagens.statusAtividade.${indexStatus}.checked`, !status.checked);
    }
    case ACTIONS.ALTERAR_TIPO_ATIVIDADE: {
      const status = state.filtroTipoAtividade.listaVisivel.find(
        item => item.id === parseInt(action.payload.idTipo, 0)
      );
      const indexTipo = state.filtroTipoAtividade.listaVisivel.findIndex(
        item => item.id === parseInt(action.payload.idTipo, 0)
      );

      const indexPagina = Object.keys(state.filtroTipoAtividade.todosItens).find(key =>
        state.filtroTipoAtividade.todosItens[key].some(x => x.id === status.id)
      );

      const newState = set(state, `filtroTipoAtividade.listaVisivel.${indexTipo}.checked`, !status.checked);
      return set(newState, `filtroTipoAtividade.todosItens.${indexPagina}.${indexTipo}.checked`, !status.checked);
    }
    case ACTIONS.ABRIR_MODAL_CANCELAR_REUNIAO: {
      const newState = set(state, "modals.cancelarReuniao.leadId", action.payload.leadId);
      return set(newState, "modals.cancelarReuniao.open", true);
    }
    case ACTIONS.FECHAR_MODAL_CANCELAR_REUNIAO: {
      const newState = set(state, "modals.cancelarReuniao.leadId", null);
      return set(newState, "modals.cancelarReuniao.open", false);
    }
    case ACTIONS.CANCELAR_REUNIAO: {
      const { leadId } = state.modals.cancelarReuniao;

      const indexFaseAgendados = state.fasesLead.findIndex(fase => fase.tipo === TIPO_FASE.AGENDADO);
      const faseAgendados = get(state, `fasesLead.${indexFaseAgendados}`);

      const lead = faseAgendados.leads.find(l => l.id === leadId);
      const indexLead = faseAgendados.leads.findIndex(l => l.id === leadId);

      const indexFaseUltimoFiltro = state.fasesLead.findIndex(fase => fase.ultimoFiltro);
      const faseUltimoFiltro = state.fasesLead.find(fase => fase.ultimoFiltro);

      let newState = set(state, `fasesLead.${indexFaseAgendados}`, {
        ...faseAgendados,
        totalLeads: faseAgendados.totalLeads - 1,
      });

      newState = set(newState, `fasesLead.${indexFaseUltimoFiltro}`, {
        ...faseUltimoFiltro,
        totalLeads: faseUltimoFiltro.totalLeads + 1,
      });

      newState = dotPropDelete(newState, `fasesLead.${indexFaseAgendados}.leads.${indexLead}`);

      return set(newState, `fasesLead.${indexFaseUltimoFiltro}.leads`, [
        { ...action.payload.cardLead, statusAtividade: lead.statusAtividade },
        ...newState.fasesLead[indexFaseUltimoFiltro].leads,
      ]);
    }
    case ACTIONS.DESCARTAR_LEAD: {
      const { leadId } = action.payload;

      const indexFaseQualificados = state.fasesLead.findIndex(fase => fase.tipo === TIPO_FASE.QUALIFICADO);
      const faseQualificados = get(state, `fasesLead.${indexFaseQualificados}`);

      const indexLead = faseQualificados.leads.findIndex(l => l.id === leadId);

      let newState = set(state, `fasesLead.${indexFaseQualificados}`, {
        ...faseQualificados,
        totalLeads: faseQualificados.totalLeads - 1,
      });

      newState = dotPropDelete(newState, `fasesLead.${indexFaseQualificados}.leads.${indexLead}`);

      return newState;
    }
    case ACTIONS.CHANGE_CAMPO_BUSCA:
      return set(state, "campoBusca", action.payload.campoBusca);
    case ACTIONS.GET_LISTAGENS_BUSCA_AVANCADA_REQUEST:
      return set(state, "isLoadingListagens", true);
    case ACTIONS.ATUALIZAR_LISTAGENS:
    case ACTIONS.GET_LISTAGENS_BUSCA_AVANCADA_RESPONSE: {
      const { listagens } = action.payload;
      return {
        ...state,
        isLoadingListagens: false,
        listagens: {
          ...state.listagens,
          ...listagens,
        },
      };
    }
    case ACTIONS.LIMPAR_BUSCA_AVANCADA: {
      const { listagens } = state;

      let newState = set(state, "isLoadingListagens", false);
      newState = set(newState, "modals.buscaAvancada.filtros", null);
      newState = set(newState, "modoBuscaAvancada", false);

      newState = set(newState, "listagens.mercados", resetListagem(listagens.mercados));
      newState = set(newState, "listagens.origens", resetListagem(listagens.origens));
      newState = set(newState, "listagens.subOrigens", resetListagem(listagens.subOrigens));
      newState = set(newState, "listagens.vendedores", resetListagem(listagens.vendedores));
      newState = set(newState, "listagens.paises", resetListagem(listagens.paises));
      newState = set(newState, "listagens.estados", resetListagem(listagens.estados));
      newState = set(newState, "listagens.cidades", resetListagem(listagens.cidades));
      newState = set(newState, "listagens.datasReferencia", resetListagem(listagens.datasReferencia));
      newState = set(newState, "listagens.dores", resetListagem(listagens.dores));
      newState = set(newState, "listagens.produtos", resetListagem(listagens.produtos));
      newState = set(
        newState,
        "listagens.camposPersonalizados",
        listagens.camposPersonalizados.map(cp => ({ ...cp, valor: "" }))
      );

      if (listagens.preVendedores) {
        newState = set(newState, "listagens.preVendedores", resetListagem(listagens.preVendedores));
      }

      return newState;
    }
    case ACTIONS.ATIVAR_MODO_BUSCA_AVANCADA:
      return set(state, "modoBuscaAvancada", true);
    case ACTIONS.ATUALIZAR_FILTROS_BUSCA_AVANCADA: {
      const isFiltrosEmptyObject =
        typeof action.payload.filtros === "object" && Object.keys(action.payload.filtros).length === 0;
      if (isFiltrosEmptyObject) return state;

      const tipoAtividadeAtual = state.modals.buscaAvancada.filtros?.tipoAtividades;
      const tipoAtividadeNova = action.payload.filtros.tipoAtividades;

      if (
        (tipoAtividadeAtual === undefined && tipoAtividadeNova === undefined) ||
        JSON.stringify(tipoAtividadeAtual) !== JSON.stringify(tipoAtividadeNova)
      ) {
        return set(state, "modals.buscaAvancada.filtros", {
          ...action.payload.filtros,
          listagens: { ...state.listagens },
        });
      }
      return state;
    }
    case ACTIONS.MUDAR_ORDENACAO: {
      const { id, sentido, campo } = action.payload;
      return set(state, `ordenacao.${id}`, { campo, sentido });
    }

    case ACTIONS.RENOMEAR_ETAPA: {
      const index = state.fasesLead.findIndex(({ id }) => id === action.payload.id);
      return set(state, `fasesLead`, [
        ...state.fasesLead.slice(0, index),
        { ...state.fasesLead[index], nome: action.payload.nome },
        ...state.fasesLead.slice(index + 1),
      ]);
    }

    case ACTIONS.RENOMEAR_FUNIL: {
      return set(state, `funil.nome`, action.payload);
    }

    case ACTIONS.FILTRAR_ETAPA: {
      const { id, filtros } = action.payload;
      const doesFiltrosPorEtapaIdExist = state.filtrosPorEtapa[id] !== undefined && filtros.tipoAgendamento !== null;
      if (doesFiltrosPorEtapaIdExist === false) return set(state, `filtrosPorEtapa.${id}`, filtros);
      if (JSON.stringify(state.filtrosPorEtapa[id]) !== JSON.stringify(filtros))
        return set(state, `filtrosPorEtapa.${id}`, filtros);
      return state;
    }

    default:
      return state;
  }
};

const resetListagem = listagem => listagem.map(item => ({ ...item, checked: false }));

export default BaseLeads;
