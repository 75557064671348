export const CommentMapper = {
  toDraftShape(commentDTO) {
    const {
      id,
      nomeAutor,
      cargoAutor,
      dataComentario,
      textoComentario,
      tempoSelecionado,
      flVisualizado,
      flPodeExcluir,
      usuarioId,
      resposta,
      ...rest
    } = commentDTO;

    return {
      id,
      createdAt: dataComentario,
      text: textoComentario,
      time: tempoSelecionado,
      isRead: flVisualizado,
      canRemove: flPodeExcluir,
      replies: resposta ? [CommentMapper.toDraftShape(resposta)] : [],
      author: {
        name: nomeAutor,
        role: cargoAutor,
      },
      ...rest,
    };
  },

  toDTOShape(commentDraft) {
    const { text, time, id, ...rest } = commentDraft;
    return {
      TextoComentario: text,
      TempoSelecionado: time,
      ...rest,
    };
  },
};

export const RatingFieldMapper = {
  toDraftShape(ratingFieldDTO) {
    const { qtdEstrelas, ...rest } = ratingFieldDTO;
    return {
      valor: qtdEstrelas,
      ...rest,
    };
  },

  toDTOShape(ratingFieldDraft) {
    const { valor, ...rest } = ratingFieldDraft;
    return {
      qtdEstrelas: valor,
      ...rest,
    };
  },
};

export const FeedbackMapper = {
  toDraftShape(feedbackDTO) {
    const { criterios, comentarios, flResolvido, flPodeResolver, ...rest } = feedbackDTO;
    return {
      commentList: comentarios?.map(comment => CommentMapper.toDraftShape(comment)) || [],
      ratingFields: criterios?.map(RatingFieldMapper.toDraftShape) || [],
      canSolveFeedback: flPodeResolver && !flResolvido,
      ...rest,
    };
  },

  toDTOShape(feedbackDraft) {
    const { commentList, ratingFields, ...rest } = feedbackDraft;
    return {
      comentarios: commentList?.map(CommentMapper.toDTOShape),
      criterios: ratingFields?.map(RatingFieldMapper.toDTOShape),
      ...rest,
    };
  },
};
