import {
  ATIVIDADES__ABRIR_MODAL_ATIVIDADE,
  ATIVIDADES__ABRIR_MODAL_ATIVIDADE_EDITAR_REQUEST,
  ATIVIDADES__ABRIR_MODAL_ATIVIDADE_EDITAR_RESPONSE,
  ATIVIDADES__FECHAR_MODAL_ATIVIDADE,
  ATIVIDADES__BUSCAR_DADOS_MODAL_REQUEST,
  ATIVIDADES__BUSCAR_DADOS_MODAL_RESPONSE,
  ATIVIDADES__BUSCAR_DADOS_MODAL_ERROR,
  ATIVIDADES__HANDLE_CHANGE_TEXTO_PESQUISA_LEAD,
  ATIVIDADES__UPDATE_IS_SEARCH_LEAD,
  ATIVIDADES__CHANGE_LEAD,
  ATIVIDADES__ADD_CONTATO_LEAD,
  ATIVIDADES__DELETE_CONTATO_LEAD,
  ATIVIDADES__CLEAR_CONTATOS_SELECINADOS_LEAD,
  ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_REQUEST,
  ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_RESPONSE,
  ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_ERROR,
  ATIVIDADES__MOSTRAR_MENSAGEM_SUCESSO,
  ATIVIDADES__ABRIR_MODAL_CONFLITOS,
  ATIVIDADES__FECHAR_MODAL_CONFLITOS,
} from "./modalAtividadesActions";

const modalAtividades = (state, action) => {
  switch (action.type) {
    case ATIVIDADES__ABRIR_MODAL_ATIVIDADE:
      return {
        ...state,
        modalAtividade: {
          ...state.modalAtividade,
          open: true,
          lead: action.payload || {},
          textoPesquisaLead: action.payload ? action.payload.nome : "",
        },
      };
    case ATIVIDADES__ABRIR_MODAL_ATIVIDADE_EDITAR_REQUEST:
      return { ...state, modalAtividade: { ...state.modalAtividade, isLoading: true } };
    case ATIVIDADES__ABRIR_MODAL_ATIVIDADE_EDITAR_RESPONSE:
      return {
        ...state,
        modalAtividade: {
          ...state.modalAtividade,
          isLoading: false,
          atividadeEdicao: action.payload,
          contatosLeadSelecionados: action.payload.contatos,
        },
      };
    case ATIVIDADES__FECHAR_MODAL_ATIVIDADE:
      return {
        ...state,
        modalAtividade: {
          open: false,
          isLoading: false,
          textoPesquisaLead: "",
          isSearchLead: false,
          mostrarMensagemSucesso: false,
          atividadeEdicao: {},
          contatosLeadSelecionados: [],
        },
      };
    case ATIVIDADES__BUSCAR_DADOS_MODAL_REQUEST:
      return { ...state, modalAtividade: { ...state.modalAtividade, isLoading: true } };
    case ATIVIDADES__BUSCAR_DADOS_MODAL_RESPONSE:
      return {
        ...state,
        modalAtividade: {
          ...state.modalAtividade,
          isLoading: false,
          tiposAtividade: action.payload.tiposAtividade,
          preVendedores: action.payload.preVendedores,
          contatosLead: action.payload.contatosLead,
          lead: action.payload.responsavel
            ? { ...state.modalAtividade.lead, responsavel: action.payload.responsavel }
            : { ...state.modalAtividade.lead },
        },
      };
    case ATIVIDADES__BUSCAR_DADOS_MODAL_ERROR:
      return { ...state, modalAtividade: { ...state.modalAtividade, open: false, isLoading: false } };
    case ATIVIDADES__HANDLE_CHANGE_TEXTO_PESQUISA_LEAD:
      return {
        ...state,
        modalAtividade: { ...state.modalAtividade, textoPesquisaLead: action.payload, contatosLead: [], lead: {} },
      };
    case ATIVIDADES__UPDATE_IS_SEARCH_LEAD:
      return { ...state, modalAtividade: { ...state.modalAtividade, isSearchLead: action.payload } };
    case ATIVIDADES__CHANGE_LEAD:
      return {
        ...state,
        modalAtividade: {
          ...state.modalAtividade,
          lead: action.payload,
          textoPesquisaLead: Object.keys(action.payload).length === 0 ? "" : action.payload.nome,
        },
      };
    case ATIVIDADES__ADD_CONTATO_LEAD:
      return {
        ...state,
        modalAtividade: {
          ...state.modalAtividade,
          contatosLeadSelecionados: [...state.modalAtividade.contatosLeadSelecionados, action.payload],
        },
      };
    case ATIVIDADES__DELETE_CONTATO_LEAD:
      return {
        ...state,
        modalAtividade: {
          ...state.modalAtividade,
          contatosLeadSelecionados: state.modalAtividade.contatosLeadSelecionados.filter(
            contato => contato.id !== action.payload
          ),
        },
      };
    case ATIVIDADES__CLEAR_CONTATOS_SELECINADOS_LEAD:
      return {
        ...state,
        modalAtividade: {
          ...state.modalAtividade,
          contatosLeadSelecionados: [],
        },
      };
    case ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_REQUEST:
      return state;
    case ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_RESPONSE:
      return {
        ...state,
        modalAtividade: {
          ...state.modalAtividade,
          contatosLead: action.payload.contatos,
          lead: {
            ...state.modalAtividade.lead,
            responsavel: action.payload.responsavel,
            responsavelId: action.payload.responsavel.id,
          },
        },
      };
    case ATIVIDADES__BUSCAR_CONTATOS_RESPONSAVEL_LEAD_ERROR:
      return state;
    case ATIVIDADES__MOSTRAR_MENSAGEM_SUCESSO:
      return {
        ...state,
        modalAtividade: { ...state.modalAtividade, mostrarMensagemSucesso: true, mensagemAtividade: action.payload },
      };
    case ATIVIDADES__ABRIR_MODAL_CONFLITOS:
      return {
        ...state,
        modalConflitoAtividade: {
          open: true,
          titulo: action.payload.text,
          conflitos: action.payload.conflitos,
          atividade: action.payload.atividade,
          tipoValidacao: action.payload.tipovalidacao,
        },
      };
    case ATIVIDADES__FECHAR_MODAL_CONFLITOS:
      return {
        ...state,
        modalConflitoAtividade: { open: false, titulo: "", conflitos: [], atividade: {}, tipoValidacao: "" },
      };
    default:
      return state;
  }
};

export default modalAtividades;
