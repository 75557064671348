import { set, delete as dotPropDelete, merge } from "dot-prop-immutable";
import { createSelector } from "reselect";

import {
  EMAILS__ATUALIZAR_THREADS_RESPONSE,
  EMAILS__ATUALIZAR_THREADS_REQUEST,
  EMAILS__ATUALIZAR_TOTAL_THREADS_RESPONSE,
  EMAILS__PROVIDER_EMAIL_AUTENTICADO,
  EMAILS__PROVIDER_EMAIL_NAO_AUTENTICADO,
  EMAILS__AUMENTAR_LIMITE_THREADS,
  EMAILS__RESETAR_LIMITE_THREADS,
  EMAILS__RESETAR_THREADS,
  TEMPLATES__BUSCAR_TEMPLATES_REQUEST,
  TEMPLATES__BUSCAR_TEMPLATES_SUCESSO,
  EMAILS__ABRIR_EDITOR_RESPONDER_REQUEST,
  EMAILS__ABRIR_EDITOR_RESPONDER_RESPONSE,
  EMAILS__FECHAR_EDITOR_RESPONDER,
  EMAILS__ABRIR_MODAL_LER_EMAIL_REQUEST,
  EMAILS__ABRIR_MODAL_LER_EMAIL_RESPONSE,
  EMAILS__ABRIR_ESCREVER_EMAIL,
  EMAILS__FECHAR_MODAL_ESCREVER_EMAIL,
  EMAILS__FECHAR_MODAL_LER_EMAIL,
  EMAILS__ABRIR_MODAL_ESCOLHER_CONTATO,
  EMAILS__FECHAR_MODAL_ESCOLHER_CONTATO,
  EMAILS__ABRIR_MODAL_SEM_CONTATOS,
  EMAILS__FECHAR_MODAL_SEM_CONTATOS,
  EMAILS__ABRIR_MODAL_ALERTA_VARIAVEIS,
  EMAILS__FECHAR_MODAL_ALERTA_VARIAVEIS,
  EMAILS__TROCAR_ABA,
  EMAILS__ADICIONAR_NEXT_PAGE_TOKEN,
  EMAILS__ATUALIZAR_INDICE_PAGE_TOKEN_ATUAL,
  EMAILS__MARCAR_THREAD_LIDA,
  EMAILS__ADICIONAR_EMAILTO,
  EMAILS__REMOVER_EMAILTO,
  EMAILS__LIMPAR_RESPONDER_EMAILS,
  EMAILS__SELECIONAR_TEMPLATE,
  EMAILS__BUSCAR_INFO_TRACKING_RESPONSE,
  EMAILS__ABRIR_MODAL_VISUALIZAR_EMAIL,
  EMAILS__FECHAR_MODAL_VISUALIZAR_EMAIL,
  EMAILS__ADICIONAR_ASSINATURA_ENVIO_EMAIL,
  EMAILS__ADICIONAR_LINK_ANEXO,
  EMAILS__ATUALIZAR_LINK_ANEXO,
  EMAILS__REMOVER_LINK_ANEXO,
  EMAILS__ABRIR_MODAL_CONFIG,
  EMAILS__FECHAR_MODAL_CONFIG,
  EMAILS__INICIAR_BUSCA_THREADS,
  EMAILS__FINALIZAR_BUSCA_THREADS,
  EMAILS__ALTERAR_BUSCA_THREADS,
  EMAILS__ADICIONAR_USUARIO_LOGADO,
  EMAILS__ADICIONAR_EMAILCC,
  EMAILS__REMOVER_EMAILCC,
  EMAILS__ADICIONAR_EMAILCCO,
  EMAILS__REMOVER_EMAILCCO,
  EMAILS__ABRIR_MODAL_NOVO_TEMPLATE,
  EMAILS__FECHAR_MODAL_NOVO_TEMPLATE,
  EMAILS__SET_TEMPLATE_ID_MODAL_NOVO_TEMPLATE,
  EMAILS__ABRIR_MODAL_EXCLUIR_TEMPLATE,
  EMAILS__FECHAR_MODAL_EXCLUIR_TEMPLATE,
  EMAILS__SET_READONLY_MODAL_NOVO_TEMPLATE,
  EMAILS__SET_READONLY_BOTAO_ENVIAR,
  EMAILS__ESCONDER_BOTAO_RESPONDER,
  EMAILS__MARCARLIDO,
  EMAILS__CHANGE_FLAG,
  EMAILS__CHANGE_IMPORTANT,
  EMAILS__ABRIR_MODAL_ASSINATURA,
  EMAILS__FECHAR_MODAL_ASSINATURA,
  EMAILS__CARREGA_THREAD_NORMALIZADA,
  EMAILS__ATUALIZAR_MESSAGEID_CONTEUDO_VALUE,
  EMAILS__SET_PARAMETROS_EMAIL,
} from "./emailsActions";
import { ENTRADA } from "../../../pages/central-emails/constantes";

export const returnInitialState = () => ({
  parametros: {
    ocultaBotaoVariaveis: false,
    permiteUtilizarTemplates: true,
    isCadencia: false,
    marcarEmailRespondido: false,
    leadIdSelecionado: null,
    emailSelecionado: null,
    substituiVariaveisTemplate: null,
  },
  usuarioLogado: {},
  threads: [],
  isAutenticado: null,
  isAutenticacaoComFalha: false,
  isCarregando: false,
  limiteEmails: 20,
  templates: [],
  responderEmail: {
    mostrarEditor: false,
    emailsTo: [],
    emailsCc: [],
    emailsCco: [],
    linksAnexos: [],
    isEncaminhando: false,
    isResponderTodos: false,
    esconderBotaoResponder: false,
    exibirModalSemContatos: false,
  },
  arrPageToken: [undefined],
  indicePageTokenAtual: 0,
  abaAtual: ENTRADA,
  modais: {
    modalVisualizarEmail: { open: false, thread: {} },
    config: false,
    assinatura: false,
    novoTemplate: { open: false, templateId: null, readOnly: false },
    excluirTemplate: { open: false, templateId: null },
  },
  busca: { exibeBusca: false, query: undefined },
});

const emails = (state = returnInitialState(), action) => {
  switch (action.type) {
    case EMAILS__ATUALIZAR_THREADS_RESPONSE:
      return {
        ...state,
        threads: action.payload.threads,
        isCarregando: false,
      };
    case EMAILS__ATUALIZAR_THREADS_REQUEST:
      return {
        ...state,
        isCarregando: true,
      };
    case EMAILS__ATUALIZAR_TOTAL_THREADS_RESPONSE:
      return {
        ...state,
        totalThreads: action.payload,
      };
    case EMAILS__PROVIDER_EMAIL_AUTENTICADO:
      return {
        ...state,
        isAutenticado: true,
        usuarioLogado: action.payload,
      };
    case EMAILS__PROVIDER_EMAIL_NAO_AUTENTICADO:
      return {
        ...state,
        ...action.payload,
        isAutenticado: false,
      };
    case EMAILS__AUMENTAR_LIMITE_THREADS:
      return {
        ...state,
        limiteEmails: state.limiteEmails + 20,
      };
    case EMAILS__RESETAR_LIMITE_THREADS:
      return {
        ...state,
        limiteEmails: returnInitialState().limiteEmails,
      };
    case EMAILS__RESETAR_THREADS:
      return {
        ...state,
        threads: [],
      };
    case TEMPLATES__BUSCAR_TEMPLATES_REQUEST:
      return {
        ...state,
        isCarregando: true,
      };
    case TEMPLATES__BUSCAR_TEMPLATES_SUCESSO:
      return {
        ...state,
        templates: action.payload,
        isCarregando: false,
      };
    case EMAILS__ABRIR_EDITOR_RESPONDER_REQUEST:
      return {
        ...state,
        responderEmail: {
          ...state.responderEmail,
          mostrarEditor: true,
          isCarregandoEmailsTo: true,
          ...action.payload,
          ...state.isResponderTodos,
        },
      };
    case EMAILS__ABRIR_EDITOR_RESPONDER_RESPONSE:
      return {
        ...state,
        responderEmail: {
          ...state.responderEmail,
          isCarregandoEmailsTo: false,
          ...action.payload,
          ...state.isResponderTodos,
        },
      };
    case EMAILS__FECHAR_EDITOR_RESPONDER:
      return {
        ...state,
        responderEmail: {
          ...state.responderEmail,
          mostrarEditor: false,
          isEncaminhando: false,
          isResponderTodos: false,
          templateId: undefined,
          ...action.payload,
        },
      };
    case EMAILS__ABRIR_MODAL_LER_EMAIL_REQUEST:
      return {
        ...state,
        mostrarModalLeitura: true,
        responderEmail: {
          ...state.responderEmail,
          threadNormalizada: action.payload.thread,
          anexosVerificados: false,
        },
      };
    case EMAILS__ABRIR_MODAL_LER_EMAIL_RESPONSE:
      return {
        ...state,
        responderEmail: { ...state.responderEmail, ...action.payload, anexosVerificados: true },
      };
    case EMAILS__ABRIR_ESCREVER_EMAIL:
      return {
        ...state,
        mostrarModalEscrever: true,
      };
    case EMAILS__ESCONDER_BOTAO_RESPONDER: {
      return {
        ...state,
        responderEmail: { ...state.responderEmail, esconderBotaoResponder: true },
      };
    }
    case EMAILS__FECHAR_MODAL_LER_EMAIL:
      return {
        ...state,
        mostrarModalLeitura: false,
        responderEmail: returnInitialState().responderEmail,
      };
    case EMAILS__FECHAR_MODAL_ESCREVER_EMAIL:
      return {
        ...state,
        mostrarModalEscrever: false,
        parametros: {
          ocultaBotaoVariaveis: false,
          permiteUtilizarTemplates: true,
          isCadencia: false,
          marcarEmailRespondido: false,
          leadIdSelecionado: null,
          emailSelecionado: null,
          substituiVariaveisTemplate: null,
        },
        responderEmail: returnInitialState().responderEmail,
      };
    case EMAILS__ABRIR_MODAL_ESCOLHER_CONTATO:
      return {
        ...state,
        responderEmail: { ...state.responderEmail, exibirModalEscolherContato: true, ...action.payload },
      };
    case EMAILS__FECHAR_MODAL_ESCOLHER_CONTATO:
      return {
        ...state,
        responderEmail: { ...state.responderEmail, exibirModalEscolherContato: false },
      };
    case EMAILS__ABRIR_MODAL_SEM_CONTATOS:
      return {
        ...state,
        responderEmail: { ...state.responderEmail, exibirModalSemContatos: true, ...action.payload },
      };
    case EMAILS__FECHAR_MODAL_SEM_CONTATOS:
      return {
        ...state,
        responderEmail: { ...state.responderEmail, exibirModalSemContatos: false },
      };
    case EMAILS__ABRIR_MODAL_ALERTA_VARIAVEIS:
      return {
        ...state,
        responderEmail: { ...state.responderEmail, exibirModalAlertaVariaveis: true, ...action.payload },
      };
    case EMAILS__FECHAR_MODAL_ALERTA_VARIAVEIS:
      return {
        ...state,
        responderEmail: { ...state.responderEmail, exibirModalAlertaVariaveis: false },
      };
    case EMAILS__TROCAR_ABA:
      return {
        ...state,
        indicePageTokenAtual: 0,
        arrPageToken: [undefined],
        abaAtual: action.payload.aba,
      };
    case EMAILS__ATUALIZAR_INDICE_PAGE_TOKEN_ATUAL:
      return {
        ...state,
        arrPageToken: action.payload.indicePageTokenAtual !== 0 ? [...state.arrPageToken] : [undefined],
        indicePageTokenAtual: action.payload.indicePageTokenAtual,
      };
    case EMAILS__ADICIONAR_NEXT_PAGE_TOKEN:
      return {
        ...state,
        arrPageToken: [...state.arrPageToken, action.payload.nextPageToken],
      };
    case EMAILS__MARCAR_THREAD_LIDA:
      return {
        ...state,
        threads: state.threads.map(thread => {
          if (thread.id === action.payload.threadId) {
            return {
              ...thread,
              isRead: false,
            };
          }
          return thread;
        }),
      };
    case EMAILS__ADICIONAR_EMAILTO:
      return merge(state, "responderEmail.emailsTo", action.payload.emailTo);
    case EMAILS__REMOVER_EMAILTO: {
      const index = state.responderEmail.emailsTo.findIndex(x => x === action.payload.emailTo);
      return dotPropDelete(state, `responderEmail.emailsTo.${index}`);
    }
    case EMAILS__ADICIONAR_EMAILCC:
      return merge(state, "responderEmail.emailsCc", action.payload.emailCc);
    case EMAILS__REMOVER_EMAILCC: {
      const index = state.responderEmail.emailsCc.findIndex(x => x === action.payload.emailCc);
      return dotPropDelete(state, `responderEmail.emailsCc.${index}`);
    }
    case EMAILS__ADICIONAR_EMAILCCO:
      return merge(state, "responderEmail.emailsCco", action.payload.emailCco);
    case EMAILS__REMOVER_EMAILCCO: {
      const index = state.responderEmail.emailsCco.findIndex(x => x === action.payload.emailCco);
      return dotPropDelete(state, `responderEmail.emailsCco.${index}`);
    }
    case EMAILS__LIMPAR_RESPONDER_EMAILS:
      return {
        ...state,
        responderEmail: {
          ...returnInitialState().responderEmail,
        },
      };
    case EMAILS__SELECIONAR_TEMPLATE:
      return {
        ...state,
        responderEmail: { ...state.responderEmail, ...action.payload },
      };
    case EMAILS__BUSCAR_INFO_TRACKING_RESPONSE: {
      return {
        ...state,
        threads: state.threads.map(thread => {
          if (thread.emails.some(email => email.id === action.payload.emailId)) {
            return {
              ...thread,
              emails: [
                ...thread.emails.map(
                  email =>
                    email.id !== action.payload.emailId
                      ? email
                      : {
                          ...email,
                          visualizacoes: action.payload.visualizacoes,
                        }
                ),
              ],
            };
          }
          return {
            ...thread,
          };
        }),
      };
    }
    case EMAILS__ABRIR_MODAL_VISUALIZAR_EMAIL:
      return {
        ...state,
        modais: {
          ...state.modais,
          modalVisualizarEmail: { open: true, thread: action.payload },
        },
      };
    case EMAILS__FECHAR_MODAL_VISUALIZAR_EMAIL:
      return {
        ...state,
        modais: {
          ...state.modais,
          modalVisualizarEmail: { open: false, thread: {} },
        },
      };
    case EMAILS__ADICIONAR_ASSINATURA_ENVIO_EMAIL:
      return {
        ...state,
        responderEmail: { ...state.responderEmail, ...action.payload },
      };
    case EMAILS__ADICIONAR_LINK_ANEXO:
      return {
        ...state,
        responderEmail: {
          ...state.responderEmail,
          linksAnexos: [...state.responderEmail.linksAnexos, action.payload.linkAnexo],
        },
      };
    case EMAILS__ATUALIZAR_LINK_ANEXO:
      return {
        ...state,
        responderEmail: {
          ...state.responderEmail,
          isReadOnlyEnviar: false,
          linksAnexos: [
            ...state.responderEmail.linksAnexos.map(
              link =>
                link.chaveBlob !== action.payload.chaveBlob
                  ? link
                  : { ...link, link: action.payload.link, completed: 100, base64: action.payload.base64 }
            ),
          ],
        },
      };
    case EMAILS__REMOVER_LINK_ANEXO:
      return {
        ...state,
        responderEmail: {
          ...state.responderEmail,
          linksAnexos: [...state.responderEmail.linksAnexos.filter(x => x.chaveBlob !== action.payload.chaveBlob)],
        },
      };
    case EMAILS__ABRIR_MODAL_CONFIG:
      return {
        ...state,
        modais: {
          ...state.modais,
          config: true,
        },
      };
    case EMAILS__FECHAR_MODAL_CONFIG:
      return {
        ...state,
        modais: {
          ...state.modais,
          config: false,
        },
      };
    case EMAILS__ABRIR_MODAL_ASSINATURA:
      return {
        ...state,
        modais: {
          ...state.modais,
          assinatura: true,
        },
      };
    case EMAILS__FECHAR_MODAL_ASSINATURA:
      return {
        ...state,
        modais: {
          ...state.modais,
          assinatura: false,
        },
      };
    case EMAILS__INICIAR_BUSCA_THREADS:
      return {
        ...state,
        busca: {
          ...state.busca,
          exibeBusca: true,
        },
      };
    case EMAILS__FINALIZAR_BUSCA_THREADS:
      return {
        ...state,
        busca: {
          ...state.busca,
          exibeBusca: false,
        },
      };
    case EMAILS__ALTERAR_BUSCA_THREADS:
      return {
        ...state,
        busca: {
          ...state.busca,
          query: action.payload.query,
        },
      };
    case EMAILS__ADICIONAR_USUARIO_LOGADO:
      return {
        ...state,
        usuarioLogado: action.payload.usuarioLogado,
        isAutenticacaoComFalha: action.payload.isAutenticacaoComFalha,
        isAutenticado: !action.payload.isAutenticacaoComFalha,
      };
    case EMAILS__ABRIR_MODAL_NOVO_TEMPLATE:
      return set(state, "modais.novoTemplate.open", true);
    case EMAILS__SET_READONLY_MODAL_NOVO_TEMPLATE:
      return set(state, "modais.novoTemplate.readOnly", action.payload.readOnly);
    case EMAILS__FECHAR_MODAL_NOVO_TEMPLATE:
      return set(state, "modais.novoTemplate", novoTemplate => ({
        ...novoTemplate,
        open: false,
        templateId: null,
        readOnly: false,
      }));
    case EMAILS__SET_TEMPLATE_ID_MODAL_NOVO_TEMPLATE:
      return set(state, "modais.novoTemplate.templateId", action.payload.id);
    case EMAILS__ABRIR_MODAL_EXCLUIR_TEMPLATE: {
      const newstate = set(state, "modais.excluirTemplate.open", true);
      return set(newstate, "modais.excluirTemplate.templateId", action.payload.templateId);
    }
    case EMAILS__FECHAR_MODAL_EXCLUIR_TEMPLATE: {
      const newstate = set(state, "modais.excluirTemplate.open", false);
      return set(newstate, "modais.excluirTemplate.templateId", null);
    }
    case EMAILS__SET_READONLY_BOTAO_ENVIAR:
      return {
        ...state,
        responderEmail: { ...state.responderEmail, isReadOnlyEnviar: action.payload, ...state.isResponderTodos },
      };
    case EMAILS__MARCARLIDO: {
      const { threads } = state;

      const novaThreads = threads.map(
        thread =>
          thread.id === action.payload
            ? {
                ...thread,
                isRead: true,
              }
            : thread
      );

      return {
        ...state,
        threads: novaThreads,
      };
    }

    case EMAILS__CHANGE_FLAG: {
      const { threads } = state;

      const novaThreads = threads.map(
        thread =>
          thread.id === action.payload.id
            ? {
                ...thread,
                isFlagged: !action.payload.isFlagged,
              }
            : thread
      );

      return {
        ...state,
        threads: novaThreads,
      };
    }

    case EMAILS__CHANGE_IMPORTANT: {
      const { threads } = state;

      const novaThreads = threads.map(
        thread =>
          thread.id === action.payload.id
            ? {
                ...thread,
                isImportant: !action.payload.isImportant,
              }
            : thread
      );

      return {
        ...state,
        threads: novaThreads,
      };
    }

    case EMAILS__ATUALIZAR_MESSAGEID_CONTEUDO_VALUE:
      return {
        ...state,
        responderEmail: {
          ...state.responderEmail,
          ...action.payload,
        },
      };

    case EMAILS__CARREGA_THREAD_NORMALIZADA:
      return {
        ...state,
        responderEmail: {
          ...state.responderEmail,
          threadNormalizada: action.payload.threadNormalizada,
          anexosVerificados: false,
        },
      };

    case EMAILS__SET_PARAMETROS_EMAIL: {
      return {
        ...state,
        parametros: {
          ...state.parametros,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export const isAutenticado = createSelector(
  [state => state.emails.isAutenticado, state => state.emails.isAutenticacaoComFalha],
  (autenticado, comFalha) => autenticado && !comFalha
);

export default emails;
