import Moment from "moment";
import { includes } from "lodash";

import {
  ATIVIDADES__CARREGAMENTO_INICIAL_REQUEST,
  ATIVIDADES__CARREGAMENTO_INICIAL_RESPONSE,
  ATIVIDADES__LISTAR_ATIVIDADES_REQUEST,
  ATIVIDADES__LISTAR_ATIVIDADES_RESPONSE,
  ATIVIDADES__SELECIONA_TIPO_FILTRO_DATA,
  ATIVIDADES__ALTERAR_DATA_FILTRO,
  ATIVIDADES__ZERAR_PAGINACAO,
  ATIVIDADES__INCREMENTA_PAGINACAO,
  ATIVIDADES__OPEN_MODAL_EXCLUIR,
  ATIVIDADES__CLOSE_MODAL_EXCLUIR,
  ATIVIDADES__HANDLE_ON_CHANGE_NOME_LEAD,
  ATIVIDADES__HANDLE_ON_CLICK_STATUS_ATIVIDADE,
  ATIVIDADES__HANDLE_ON_CLICK_TIPO_ATIVIDADE,
  ATIVIDADES__HANDLE_ON_CHANGE_PRE_VENDEDORES,
  ATIVIDADES__HANDLE_ON_CHANGE_ETAPAS,
  ATIVIDADES__RESET_LISTAS,
  ATIVIDADES__NAO_HA_ITEMS_PARA_LISTAR,
  ATIVIDADES__LEADS_SEM_ATIVIDADES_REQEST,
  ATIVIDADES__LEADS_SEM_ATIVIDADES_RESPONSE,
  ATIVIDADES__CHANGE_ORDENACAO_LEADS_SEM_ATIVIDADE,
  ATIVIDADES__LEADS_SEM_ATIVIDADES_ATUALIZAR_TOTAL,
  ATIVIDADES__ESCONDER_STATUS,
  ATIVIDADES__MOSTRAR_TODOS_STATUS,
} from "./listagemAtividadesActions";

const listagemAtividades = (state, action) => {
  switch (action.type) {
    case ATIVIDADES__CARREGAMENTO_INICIAL_REQUEST:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          isLoading: true,
        },
      };
    case ATIVIDADES__CARREGAMENTO_INICIAL_RESPONSE:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          isLoading: false,
          listaEtapas: action.payload.fasesLead.map(fase => ({ ...fase, checked: true })),
          listaPvs:
            action.payload.preVendedores &&
            action.payload.preVendedores.map(preVend => ({ ...preVend, checked: true })),
          listaTiposAtividade: [
            ...action.payload.tipoAtividade.map(tipo => ({ ...tipo, checked: true, mostrar: true })),
            ...state.listagem.tipoAtividadeSemRegistro,
          ],
          totalLeadsSemAtividades:
            action.payload.totalLeadsSemAtividades && `(${action.payload.totalLeadsSemAtividades})`,
        },
      };

    case ATIVIDADES__LISTAR_ATIVIDADES_REQUEST:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          isLoadingAtividades: true,
        },
      };
    case ATIVIDADES__LISTAR_ATIVIDADES_RESPONSE:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          atividadesAgrupada: [...state.listagem.atividadesAgrupada, ...action.payload.lista],
          totais: [...state.listagem.totais, ...action.payload.totais],
          isLoadingAtividades: false,
          filtros: {
            ...state.listagem.filtros,
            hasMore: action.payload.lista.length === 30,
          },
        },
      };
    case ATIVIDADES__SELECIONA_TIPO_FILTRO_DATA:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          filtros: {
            ...state.listagem.filtros,
            pDataInicio: new Moment().startOf(action.payload.pTipoAgrupamentoDesc),
            pDataFim: new Moment().endOf(action.payload.pTipoAgrupamentoDesc),
            pTipoAgrupamento: action.payload.pTipoAgrupamento,
          },
        },
      };
    case ATIVIDADES__ALTERAR_DATA_FILTRO:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          filtros: {
            ...state.listagem.filtros,
            pDataInicio: new Moment(state.listagem.filtros.pDataInicio).add(
              action.payload.periodo,
              action.payload.descricaoTipo
            ),
            pDataFim: new Moment(state.listagem.filtros.pDataFim).add(
              action.payload.periodo,
              action.payload.descricaoTipo
            ),
          },
        },
      };
    case ATIVIDADES__ZERAR_PAGINACAO:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          atividadesAgrupada: [],
          totais: [],
          leadsSemAtividades: [],
          filtros: {
            ...state.listagem.filtros,
            pagina: 0,
            hasMore: true,
          },
        },
      };
    case ATIVIDADES__INCREMENTA_PAGINACAO:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          filtros: {
            ...state.listagem.filtros,
            pagina: state.listagem.filtros.pagina + 1,
          },
        },
      };
    case ATIVIDADES__OPEN_MODAL_EXCLUIR:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          modalExcluir: {
            open: true,
            atividadeId: action.payload,
          },
        },
      };
    case ATIVIDADES__CLOSE_MODAL_EXCLUIR:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          modalExcluir: {
            open: false,
            atividadeId: null,
          },
        },
      };
    case ATIVIDADES__HANDLE_ON_CHANGE_NOME_LEAD:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          filtros: {
            ...state.listagem.filtros,
            pNomeLead: action.payload,
          },
        },
      };
    case ATIVIDADES__HANDLE_ON_CLICK_STATUS_ATIVIDADE: {
      const statusAtividadeAtualizada = state.listagem.listaStatusAtividade.map(status => {
        if (status.id.toString() === action.payload) {
          status.checked = !status.checked;
        }
        return status;
      });

      return {
        ...state,
        listagem: {
          ...state.listagem,
          listaStatusAtividade: statusAtividadeAtualizada,
        },
      };
    }
    case ATIVIDADES__HANDLE_ON_CLICK_TIPO_ATIVIDADE: {
      const tiposAtividadeAtualizada = state.listagem.listaTiposAtividade.map(tipoAtividade => {
        if (tipoAtividade.id.toString() === action.payload) {
          tipoAtividade.checked = !tipoAtividade.checked;
        }
        return tipoAtividade;
      });

      return {
        ...state,
        listagem: {
          ...state.listagem,
          listaTiposAtividade: tiposAtividadeAtualizada,
        },
      };
    }
    case ATIVIDADES__HANDLE_ON_CHANGE_PRE_VENDEDORES: {
      const listPvsAtualizada = state.listagem.listaPvs.map(preVend => {
        preVend.checked = includes(action.payload, preVend.id.toString());
        return preVend;
      });

      return {
        ...state,
        listagem: {
          ...state.listagem,
          listaPvs: listPvsAtualizada,
        },
      };
    }
    case ATIVIDADES__HANDLE_ON_CHANGE_ETAPAS: {
      const listEtapasAtualizada = state.listagem.listaEtapas.map(etapa => {
        etapa.checked = includes(action.payload, etapa.id.toString());
        return etapa;
      });

      return {
        ...state,
        listagem: {
          ...state.listagem,
          listaEtapas: listEtapasAtualizada,
        },
      };
    }
    case ATIVIDADES__RESET_LISTAS:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          listaEtapas: state.listagem.listaEtapas.map(etapa => ({ ...etapa, checked: true })),
          listaPvs: state.listagem.listaPvs && state.listagem.listaPvs.map(preVend => ({ ...preVend, checked: true })),
          listaTiposAtividade: state.listagem.listaTiposAtividade.map(tipo => ({
            ...tipo,
            checked: true,
            mostrar: tipo.id !== 0,
          })),
          filtros: {
            ...state.listagem.filtros,
            pNomeLead: "",
          },
        },
      };
    case ATIVIDADES__NAO_HA_ITEMS_PARA_LISTAR:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          atividadesAgrupada: [],
          leadsSemAtividades: [],
          filtros: {
            ...state.listagem.filtros,
            hasMore: false,
          },
        },
      };
    case ATIVIDADES__LEADS_SEM_ATIVIDADES_REQEST:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          isLoadingLeadsSemAtividade: true,
          listaTiposAtividade: state.listagem.listaTiposAtividade.map(tipo => ({ ...tipo, mostrar: true })),
        },
      };
    case ATIVIDADES__LEADS_SEM_ATIVIDADES_RESPONSE:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          leadsSemAtividades: [...state.listagem.leadsSemAtividades, ...action.payload.listaLeadsSemAtividades],
          isLoadingLeadsSemAtividade: false,
          filtros: {
            ...state.listagem.filtros,
            hasMore: action.payload.listaLeadsSemAtividades.length === 30,
          },
        },
      };
    case ATIVIDADES__CHANGE_ORDENACAO_LEADS_SEM_ATIVIDADE:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          filtros: {
            ...state.listagem.filtros,
            pColunaOrdenacao: action.payload,
            pTipoOrdenacao: state.listagem.filtros.pTipoOrdenacao === "asc" ? "desc" : "asc",
          },
        },
      };
    case ATIVIDADES__LEADS_SEM_ATIVIDADES_ATUALIZAR_TOTAL:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          totalLeadsSemAtividades: `(${action.payload})`,
        },
      };
    case ATIVIDADES__ESCONDER_STATUS: {
      const listStatusAtualizada = state.listagem.listaStatusAtividade.map(status => {
        if (status.id === action.payload) {
          status.mostrar = false;
        }
        return status;
      });

      return {
        ...state,
        listagem: {
          ...state.listagem,
          listaStatusAtividade: listStatusAtualizada,
        },
      };
    }
    case ATIVIDADES__MOSTRAR_TODOS_STATUS:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          listaStatusAtividade: state.listagem.listaStatusAtividade.map(status => ({ ...status, mostrar: true })),
        },
      };
    default:
      return state;
  }
};

export default listagemAtividades;
